<template>
  <InputWrapper
    :errors="errors"
    :has-errors="hasErrors"
    :hint="hint"
    :focused="isFocused"
    :shrink-label="isFocused || !!model.length"
  >
    <textarea
      v-bind="$attrs"
      :id="name"
      ref="input"
      v-model="model"
      :data-qa="dataQa"
      :name="name"
      rows="3"
      class="textarea-field"
      @blur="onBlur"
      @focus="onFocus"
      @input="(e) => emit('input', e)"
      @keypress="(e) => emit('keypress', e)"
    />
    <template
      v-if="hasSlot['append']"
      #append
    >
      <slot name="append" />
    </template>
  </InputWrapper>
</template>

<script setup>
import { ref } from 'vue'
import InputWrapper from './InputWrapper.vue'
import useVueSlots from '@shared/composables/useVueSlots.js'

const { hasSlot } = useVueSlots(['append'])
const input = ref()
const emit = defineEmits(['input', 'blur', 'keypress', 'focus'])
defineOptions({
  inheritAttrs: false,
})

defineProps({
  label: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  dataQa: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
})

const model = defineModel({ type: String, default: '' })

const isFocused = ref(false)

const onBlur = (e) => {
  isFocused.value = false
  emit('blur', e)
}

const onFocus = (e) => {
  isFocused.value = true
  emit('focus', e)
}

defineExpose({ input })
</script>

<style lang="scss">
:root {
  --textarea-field--padding: 20px 16px;
}

.wrapper {
  &__container {
    @include grid-container(1fr, flex-start, 0);

    &--field {
      &--input {
        .textarea-field {
          appearance: none;
          background-color: transparent;
          border: 0;
          color: $text-secondary;
          display: block;
          outline: 0;
          padding: var(--textarea-field--padding);
          width: 100%;
          box-sizing: border-box;

          &:-webkit-autofill {
            animation-name: onAutoFillStart;
          }

          &:not(:-webkit-autofill) {
            animation-name: onAutoFillCancel;
          }

          &::placeholder {
            color: $text-tertiary;
            font-weight: 500;
            opacity: 1;
          }

          @include kaia-typography-generic(17px, 22.1px, $font-weight-medium);
        }
      }
    }
  }
}
</style>
