<template>
  <a
    :href="href"
    class="a-link"
  >
    {{ cta }}
  </a>
</template>

<script setup>
import { computed } from 'vue'
import env from '@shared/env'

defineProps({
  cta: {
    type: String,
    default: env.VITE_SUPPORT_EMAIL,
  },
})

const href = computed(() => `mailto:${env.VITE_SUPPORT_EMAIL}`)
</script>
