import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { getDefaultLanguage } from '@shared/utils'
import { sessionStorage } from '@shared/helpers/storage'
import assessment from './assessment/index'
import b2b from './b2b/index'
import actions from './actions'

const modules = {
  assessment,
  b2b,
}

export default new Vuex.Store({
  strict: import.meta.env.NODE_ENV !== 'production',
  state: {
    /** @type {User} */
    user: null,
    auth: {},
    country: 'US',
    lang: getDefaultLanguage(),
    checkInStatus: '',
    hostAppIdentifiers: {},
  },
  actions,
  getters: {
    user: (state) => state.user,
    auth: (state) => state.auth,
    userName: (state) => state.user?.profile.firstName,
    country: (state) => state.country,
    lang: (state) => state.lang,
    isPro: (state) => state.user?.isPro,
    checkInStatus: (state) => state.checkInStatus,
    hostAppIdentifiers: (state) => state.hostAppIdentifiers,
  },
  mutations: {
    user(state, value) {
      state.user = value
    },
    auth(state, value) {
      state.auth = value
    },
    lang(state, value) {
      state.lang = value
    },
    setCheckInStatus(state, value) {
      state.checkInStatus = value
    },
    hostAppIdentifiers(state, value) {
      state.hostAppIdentifiers = value
    },
  },
  modules,
  plugins: [
    new VuexPersistence({
      storage: sessionStorage,
    }).plugin,
  ],
})
