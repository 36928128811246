<template>
  <div class="quiz-content__inner self-test past-medication">
    <div class="self-test__layout">
      <div>
        <div>
          <h3
            class="self-test__header"
            v-html="$t('b2b__self_test__msk_us__past_medication__header')"
          />
          <div
            class="self-test__description"
            v-html="$t('b2b__self_test__msk_us__past_medication__description')"
          />
        </div>
        <fieldset class="form-container">
          <div
            v-for="item in items"
            :key="item.key"
            :class="[
              'quiz-checkbox',
              { 'quiz-checkbox--checked': isChecked(item) },
            ]"
            @click="handleCheckboxChange(item)"
          >
            <label class="quiz-checkbox__label kaia-p2-semi-bold">
              {{ $t(item.value) }}
            </label>
            <div class="quiz-checkbox__layout">
              <CustomCheckbox
                :value="item.key"
                :checked="isChecked(item)"
                :data-q-a="item.key"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div :class="interactiveButtonCSSClasses">
        <InteractiveButton
          state="ACTIVE"
          :text="interactiveButtonText"
          data-q-a="submit"
          @click="handleNextEvent()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomCheckbox from '@shared/components/CustomCheckbox.vue'
import InteractiveButton from '@shared/components/InteractiveButton.vue'

import pastMedicationsList from '@msk-us/config/past-medications.json'

export default {
  name: 'SelfTestPastMedication',
  components: {
    CustomCheckbox,
    InteractiveButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      items: pastMedicationsList.concat([]),
    }
  },
  computed: {
    ...mapGetters('assessment', ['selectedPastMedications']),
    interactiveButtonText() {
      return this.selectedPastMedications.length > 0
        ? this.$t('generic_action_continue')
        : this.$t('b2b__self_test__msk_us__past_medication__none_of_these')
    },
    interactiveButtonCSSClasses() {
      return [
        'interactive-button__layout',
        this.selectedPastMedications.length > 0
          ? ''
          : 'interactive-button-outlined',
      ]
    },
  },
  methods: {
    handleCheckboxChange(item) {
      let items = this.selectedPastMedications.concat([])
      if (items.includes(item.key)) {
        items = items.filter((i) => i !== item.key)
      } else {
        items.push(item.key)
      }
      this.$store.commit('assessment/updatePastMedications', items)
    },
    isChecked(item) {
      return this.selectedPastMedications.includes(item.key)
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/views/css/Assessment/SelfTest';
.past-medication {
  .interactive-button__layout {
    margin: 32px auto 0;
  }

  .form-container {
    width: 100%;
    @media (max-width: $breakpoint-tablet) {
      flex: 0 0 50%;
    }
    ul {
      list-style: none;
      padding: 0;
      max-width: 420px;
      margin: 0 auto;
    }
    li {
      margin-bottom: 8px;
    }
  }
}
</style>
