import { createApp } from 'vue'
import App from '@msk-us/App.vue'
import initRouter from '@msk-us/router'
import store from '@msk-us/state/store'
import i18n from '@shared/localization'
import { initApi } from '@shared/api-client'
import directives from '@shared/directives'
import Tracker from '@shared/Tracker'
import getCorporateTrackingSearchParams from '@msk-us/helpers/getCorporateTrackingSearchParams'
import { generateSignupContext } from '@shared/utils'

// replace corporate and query from tracking map before router, tracker, and signupContext are initialized
const nextQueryParams = getCorporateTrackingSearchParams(
  window.location.search,
  'corporate',
)
if (nextQueryParams != null) {
  const querystring = new URLSearchParams(nextQueryParams).toString()
  const url = `${window.location.pathname}?${querystring}${window.location.hash}`
  window.history.replaceState({}, '', url)
}

const app = createApp(App)

Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key])
})

initApi(store)
Tracker.initDatadog()

app.use(initRouter())
app.use(store)
app.use(i18n)

store.commit('b2b/signupContext', generateSignupContext())

const appSpinner = document.querySelector('#app-spinner')
if (appSpinner != null) {
  appSpinner.parentNode.removeChild(appSpinner)
}
app.mount('#app')
// tracking is enabled by default
;(function checkTrackingAcceptance() {
  let trackingAccepted = null

  try {
    trackingAccepted = new Event('trackingAccepted')
  } catch {
    trackingAccepted = document.createEvent('Event')
    trackingAccepted.initEvent('trackingAccepted', false, false)
  }
  document.dispatchEvent(trackingAccepted)
})()

export default app
