<template>
  <div class="header-ctrls">
    <button
      type="button"
      role="button"
      aria-label="Back"
      :class="backButtonCSSClasses"
      @click="handleClickEvent('back')"
    />
    <div class="header-ctrls__right">
      <button
        v-if="ctrls.lang"
        type="button"
        role="button"
        class="header-ctrls__item"
        :aria-label="getNextLanguage.text"
        @click="updateLang(getNextLanguage)"
      >
        {{ getLanguageSwitchTitle }}
      </button>
      <button
        v-if="ctrls.support"
        type="button"
        role="button"
        class="header-ctrls__item"
        aria-label="Help Section"
        @click="handleClickEvent('support')"
      >
        ?
      </button>
      <button
        v-if="ctrls.close"
        type="button"
        role="button"
        class="header-ctrls__item"
        aria-label="Close"
        @click="handleClickEvent('close')"
      >
        &#x2715;
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  normalizeLang,
  getLangCodeByLanguage,
  getSupportedLangCodes,
} from '@shared/utils'

// TODO move these to lokalise
const LANG_OPTIONS = {
  'de-DE': 'Deutsch',
  'en-US': 'English',
}

export default {
  name: 'HeaderCtrls',
  props: {
    ctrls: {
      type: Object,
      default: () => ({
        back: 1,
        lang: 1,
        support: 1,
        close: 0,
      }),
    },
  },
  emits: ['action'],
  computed: {
    ...mapGetters(['lang']),
    langOptions() {
      const availableLangs = getSupportedLangCodes()
      return availableLangs.map((lang) => {
        lang = normalizeLang(lang)

        return {
          value: lang,
          text: LANG_OPTIONS[lang] || lang,
        }
      })
    },
    getNextLanguage() {
      const currentLangLocale = normalizeLang(getLangCodeByLanguage(this.lang))
      return (
        this.langOptions.filter((l) => l.value !== currentLangLocale)[0] ||
        this.langOptions[0]
      )
    },
    getLanguageSwitchTitle() {
      return this.getNextLanguage.value === 'de-DE' ? 'DE' : 'EN'
    },
    backButtonCSSClasses() {
      const css = ['header-ctrls__item', 'header-ctrls__back']
      if (this.ctrls.back) {
        return css
      }

      css.push('header-ctrls__item--hidden')
      return css
    },
  },
  methods: {
    ...mapActions({
      setLang: 'setLang',
    }),
    updateLang(language) {
      this.setLang(language.value)
    },
    handleClickEvent(type) {
      this.$emit('action', type)
    },
  },
}
</script>

<style lang="scss">
@import '@shared/components/css/HeaderCtrls.scss';
</style>
