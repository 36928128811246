<template>
  <div class="footer">
    <div :class="getFooterInfoClass">
      <div class="footer-contacts">
        <div class="footer-contacts-layout">
          <div class="footer-company">
            <div
              class="footer-contacts__label"
              v-html="staticContactsName"
            />
            <span
              class="footer-contacts__address"
              v-html="staticCompanyAddress"
            />
          </div>
          <div class="footer-email">
            <div
              class="footer-contacts__label"
              v-html="staticContactsEmailLabel"
            />
            <a
              :href="`mailto:${mailto}`"
              class="footer-contacts__link"
            >
              {{ mailto }}
            </a>
          </div>
          <div class="footer-acknowledgements">
            <div
              class="footer-contacts__label"
              v-html="staticAcknowledgementsLabel"
            />
            <BaseModal can-click-outside>
              <template #trigger="{ open }">
                <p
                  class="footer-contacts__link"
                  @click.stop="open"
                >
                  {{ staticAcknowledgementsLinkCaption }}
                </p>
              </template>
              <Acknowledgements />
            </BaseModal>
          </div>
          <div class="footer-slot">
            <LanguageSwitcher />
          </div>
        </div>
      </div>

      <div
        v-if="shouldDisplayLegal"
        class="footer-legal"
      >
        <div class="footer-legal-layout">
          <div
            class="footer-legal__label"
            v-html="staticLegalLabel"
          />
          <div
            v-if="kaiaTermsEnabled"
            class="footer-legal__terms"
          >
            <a
              href="#"
              class="footer-legal__link"
              :data-link="termsLink"
              data-custom="false"
              @click="onClickLink"
            >
              {{ staticTermsLink }}
            </a>
          </div>
          <div
            v-if="kaiaTermsEnabled"
            class="footer-legal__privacy"
          >
            <a
              href="#"
              class="footer-legal__link"
              :data-link="policyLink"
              data-custom="false"
              @click="onClickLink"
            >
              {{ staticPrivacyLink }}
            </a>
          </div>
          <div
            v-if="shouldDisplayDisclaimer"
            class="footer-legal__disclaimer"
          >
            <a
              href="#"
              class="footer-legal__link"
              @click="openModal"
            >
              {{ staticDisclaimerLink }}
            </a>
          </div>
        </div>
      </div>

      <div class="footer-certificates">
        <div class="footer-certificates-layout">
          <div
            class="footer-certificates__label"
            v-html="staticCertificatesTitle"
          />
          <div class="footer-certificates-list">
            <div
              class="footer-certificates__medical-device footer-certificates-list__item"
              v-html="staticCertificatesMedicalDevice"
            />
            <div
              class="footer-certificates__tuev footer-certificates-list__item"
              v-html="staticCertificatesISO13485"
            />
            <div
              class="footer-certificates__gdpr footer-certificates-list__item"
              v-html="staticCertificatesGDPR"
            />
            <div
              class="footer-certificates__hipaa footer-certificates-list__item"
              v-html="staticCertificatesHIPAA"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import IframeModal from '@shared/components/IframeModal.vue'
import DisclaimerModal from '@shared/components/DisclaimerModal.vue'
import $modals from '@shared/modal/index'
import defineLanguage from '@shared/utils/defineLanguage'
import Acknowledgements from '@shared/components/Acknowledgements.vue'
import BaseModal from '@shared/components/BaseModal.vue'

export default {
  name: 'LandingPageFooter',
  components: {
    BaseModal,
    Acknowledgements,
    LanguageSwitcher,
    // eslint-disable-next-line vue/no-unused-components
    DisclaimerModal,
  },
  props: {
    staticCompanyAddress: {
      type: String,
      default: '',
    },
    corporate: {
      type: Object,
      default: () => null,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  computed: {
    mailto() {
      return import.meta.env.VITE_SUPPORT_EMAIL
    },
    kaiaTermsEnabled() {
      return this.corporate?.kaiaTermsEnabled
    },
    disclaimerEnabled() {
      return this.corporate?.disclaimerEnabled
    },
    disclaimerText() {
      return this.disclaimerEnabled ? this.corporate.disclaimerText : null
    },
    shouldDisplayLegal() {
      return this.kaiaTermsEnabled || this.disclaimerEnabled
    },
    shouldDisplayDisclaimer() {
      return this.disclaimerEnabled && !!this.disclaimerText
    },
    getFooterInfoClass() {
      return this.shouldDisplayLegal ? 'footer-expanded' : 'footer-standard'
    },
    staticLegalLabel() {
      return this.$t('b2b_static_legal_label')
    },
    staticTermsLink() {
      return this.$t('b2b_static_terms_conditions_link')
    },
    staticPrivacyLink() {
      return this.$t('b2b_static_privacy_policy_link')
    },
    staticDisclaimerLink() {
      return this.$t('b2b_static_disclaimer_link')
    },
    staticContactsName() {
      return this.$t('b2b_static_contacts_name')
    },
    staticContactsEmailLabel() {
      return this.$t('b2b_static_contacts_email_label')
    },
    staticAcknowledgementsLabel() {
      return this.$t('b2b_static_acknowledgements_label')
    },
    staticAcknowledgementsLinkCaption() {
      return this.$t('b2b_static_acknowledgements_link_caption')
    },
    staticCertificatesTitle() {
      return this.$t('b2b_static_certificates_title')
    },
    staticCertificatesMedicalDevice() {
      return this.$t('b2b_static_certificates_registered_medical_device')
    },
    staticCertificatesISO13485() {
      return this.$t('b2b_static_certificates_iso_13485')
    },
    staticCertificatesGDPR() {
      return this.$t('b2b_static_certificates_gdpr')
    },
    staticCertificatesHIPAA() {
      return this.$t('b2b_static_certificates_hipaa')
    },
    termsLink() {
      return this.staticLinks.termsLink
    },
    policyLink() {
      return this.staticLinks.policyLink
    },
    staticLinks() {
      const definedLang = defineLanguage(this.lang)
      const documentLang =
        import.meta.env.VITE_GEO === 'us'
          ? definedLang === 'es'
            ? definedLang
            : 'us'
          : definedLang
      const digaPart = this.digaContext ? '' : '?diga=false'
      const base = import.meta.env.VITE_PUBLIC_PATH
      return {
        termsLink: `${base}static/${documentLang}/terms/index.html${digaPart}`,
        policyLink: `${base}static/${documentLang}/privacy/index.html${digaPart}`,
      }
    },
  },
  methods: {
    onClickLink(e) {
      e.stopPropagation()
      const { link, custom } = e.target.dataset
      if (link) {
        e.preventDefault()
        if (custom === 'true') {
          window.open(link, '_blank')
        } else {
          this.showDocument(link)
        }
      }
    },
    showDocument(documentUrl) {
      // this.modalDocumentUrl = documentUrl;
      $modals.open({
        component: IframeModal,
        props: {
          documentUrl,
        },
      })
    },
    openModal(e) {
      e.preventDefault()
      $modals.open({
        component: DisclaimerModal,
        props: {
          closeModal: this.closeModal,
          disclaimerText: this.disclaimerEnabled ? this.disclaimerText : '',
        },
      })
    },
    closeModal(e) {
      e.preventDefault()
      $modals.close()
      // this.onClose();
    },
  },
}
</script>

<style lang="scss">
.footer {
  background-color: $white;
  padding: 59px 0 30px;

  &-language {
    border-bottom: 1px solid $gray-5;

    &-layout {
      padding: 0 24px 20px;
    }
  }

  &-legal {
    padding: 19px 0 20px;
    border-bottom: 1px solid $gray-5;

    &-layout {
      display: flex;
      flex-direction: column;
      padding: 0 24px;

      div {
        padding: 11px 0;
      }
    }

    &__link {
      color: $ocean;
      text-decoration: none;

      @include kaia-typography-p1($font-weight-normal);
    }

    &__label {
      padding-bottom: 9px;

      @include kaia-typography-p2($font-weight-bold);
    }
  }

  &-contacts {
    padding: 19px 0 20px;
    border-bottom: 1px solid $gray-5;

    &-layout {
      display: flex;
      flex-direction: column;
      padding: 0 24px;
    }

    &__address {
      color: $gray-50;

      @include kaia-typography-p1($font-weight-normal);
    }

    &__link {
      color: $ocean;
      text-decoration: none;
      cursor: pointer;

      @include kaia-typography-p1($font-weight-normal);
    }

    &__label {
      padding-bottom: 9px;

      @include kaia-typography-p2($font-weight-bold);
    }
  }

  &-certificates {
    padding: 19px 0 0;

    &-layout {
      padding: 0 24px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      color: $gray-50;

      &__item {
        padding: 11px 0 11px 46px;
        display: flex;
        align-items: center;

        @include kaia-typography-p1($font-weight-normal);
      }
    }

    &__label {
      padding-bottom: 12px;

      @include kaia-typography-p2($font-weight-bold);
    }

    &__medical-device {
      height: 25px;
      background: url('@shared/assets/img/b2b/icon-ce.svg') left center
        no-repeat;
    }

    &__tuev {
      height: 32px;
      background: url('@shared/assets/img/b2b/icon-check-figure.svg') left
        center no-repeat;
    }

    &__gdpr {
      height: 33px;
      background: url('@shared/assets/img/b2b/icon-security.svg') left center
        no-repeat;
    }

    &__hipaa {
      height: 33px;
      background: url('@shared/assets/img/b2b/icon-health.svg') left center
        no-repeat;
    }
  }

  &-company {
    padding-bottom: 21px;
    min-width: 280px;
  }

  &-email {
    padding-bottom: 18px;
    min-width: 280px;
  }

  &-acknowledgements {
    padding-bottom: 18px;
    min-width: 280px;
  }

  &-slot {
    padding-bottom: 18px;
    min-width: 280px;
  }
}

.footer {
  @media (min-width: $size-small) {
    padding: 48px 0 30px;
  }

  @media (min-width: $size-medium) {
    padding: 72px 0 30px;
  }

  @media (min-width: $size-large-x) {
    padding: 96px 0 30px;
  }
  &-expanded {
    @media (min-width: $size-large) {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: $size-large-xx) {
      justify-content: space-between;
    }
    .footer-certificates-list,
    .footer-contacts-layout {
      @media (min-width: $size-large) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &-standard {
    .footer-language-layout {
      @media (min-width: $size-large-xx) {
        width: 1200px;
        padding: 0 0 38px;
        margin: 0 auto;
      }
    }
    .footer-contacts-layout,
    .footer-certificates-layout {
      @media (min-width: $size-large-x) {
        display: flex;
      }
      @media (min-width: $size-large-xx) {
        width: 1200px;
        padding: 0;
        margin: 0 auto;
      }
    }
    .footer-certificates-list {
      @media (min-width: $size-medium) {
        align-items: center;
      }
      @media (min-width: $size-large-x) {
        display: flex;
      }
    }
  }

  &-language {
    &-layout {
      @media (min-width: $size-small) {
        padding: 0 24px 20px;
      }

      @media (min-width: $size-medium) {
        padding: 0 54px 38px;
      }

      @media (min-width: $size-large) {
        padding: 0 82px 38px;
      }
    }
  }

  &-contacts {
    @media (min-width: $size-medium) {
      padding: 15px 0 0;
    }

    &-layout {
      @media (min-width: $size-small) {
        padding: 0 24px;
      }

      @media (min-width: $size-medium) {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        padding: 0 54px 0;
        padding-right: 0;
      }

      @media (min-width: $size-large) {
        padding: 0 82px;
      }
    }
  }

  &-legal {
    @media (min-width: $size-medium) {
      padding: 15px 0 0;
    }

    &-layout {
      @media (min-width: $size-small) {
        padding: 0 24px;
      }

      @media (min-width: $size-medium) {
        padding: 0 54px 0;
        padding-right: 0;
      }
    }

    &__link {
      color: $ocean;
      text-decoration: none;

      @include kaia-typography-p1($font-weight-normal);
    }

    &__label {
      padding-bottom: 9px;

      @include kaia-typography-p2($font-weight-bold);
    }
  }

  &-certificates {
    @media (min-width: $size-medium) {
      padding: 31px 0 0;
    }

    &-layout {
      @media (min-width: $size-small) {
        padding: 0 24px;
      }

      @media (min-width: $size-medium) {
        padding: 0 54px;
      }

      @media (min-width: $size-large) {
        padding: 0 82px;
      }

      @media (min-width: $size-large-x) {
        align-items: center;
        justify-content: space-between;
      }
    }

    &__label {
      @media (min-width: $size-large-x) {
        padding-bottom: 0;
      }
    }

    &-list {
      @media (min-width: $size-medium) {
        flex-wrap: wrap;
        flex-direction: row;
      }

      @media (min-width: $size-large) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      @media (min-width: $size-large-x) {
        flex-basis: 85%;
      }

      &__item {
        @media (min-width: $size-medium) {
          padding-right: 44px;
        }

        @media (min-width: $size-large) {
          padding-right: 0;
        }
      }
    }
  }

  &-company {
    @media (min-width: $size-medium) {
      padding-top: 23px;
      padding-right: 54px;
    }

    @media (min-width: $size-large) {
      padding-right: 0;
    }
  }

  &-email {
    @media (min-width: $size-medium) {
      padding-top: 23px;
      padding-right: 54px;
    }

    @media (min-width: $size-large) {
      padding-right: 0;
    }
  }

  &-acknowledgements {
    @media (min-width: $size-medium) {
      padding-top: 23px;
      padding-right: 54px;
    }

    @media (min-width: $size-large) {
      padding-right: 0;
    }
  }

  &-slot {
    @media (min-width: $size-medium) {
      padding-top: 23px;
      padding-right: 54px;
    }

    @media (min-width: $size-large) {
      padding-right: 0;
    }
  }
}
</style>
