<template>
  <!-- eslint-disable vue/no-v-html -->
  <slot v-bind="{ viewDocument }">
    <span
      @click.stop="viewDocument"
      v-html="link"
    />
  </slot>
  <BaseModal
    ref="modal"
    :width="1000"
    can-click-outside
  >
    <iframe :src="doc"></iframe>
  </BaseModal>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref } from 'vue'
import BaseModal from '@shared/components/BaseModal.vue'

/**
 * @link [HTML] Should come from translation file and specify an a tag which references static documents.
 * For example: <a class="a-link" href="#" data-link="/static/us/terms/index.html">Terms and Conditions</a>
 * DataLink should be the path to the static document
 */
defineProps({
  link: {
    type: String,
    default: '',
  },
})

const modal = ref(null)
const doc = ref(null)

const viewDocument = (e) => {
  const { link } = e.target.dataset
  if (link) {
    // only prevent click events when the user actually clicked on a link
    e.preventDefault()

    doc.value = link
    modal.value.open()
  }
}
</script>

<style lang="scss" scoped>
iframe {
  border: none;
  width: 100%;
  height: 80vh;
}
</style>
