import * as snowplowTracker from '@snowplow/browser-tracker'
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger'
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  trackLinkClick,
} from '@snowplow/browser-plugin-link-click-tracking'
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing'
import env from '@shared/env'
import { getCookie, setCookie } from '@shared/utils'

import User from './data/models/user'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const getSnowplowTracker = Promise.all([
  new Promise((resolve) => {
    const handleCookieConsentAccepted = () => {
      document.removeEventListener(
        'cookieConsentAccepted',
        handleCookieConsentAccepted,
      )
      resolve()
    }
    document.addEventListener(
      'cookieConsentAccepted',
      handleCookieConsentAccepted,
    )
  }),
  new Promise((resolve) => {
    const handleTrackingAccepted = () => {
      document.removeEventListener('trackingAccepted', handleTrackingAccepted)
      resolve()
    }
    document.addEventListener('trackingAccepted', handleTrackingAccepted)
  }),
]).then(() => {
  const tracker = snowplowTracker.newTracker(
    'web-checkout-tracker',
    env.VITE_SNOWPLOW_COLLECTOR,
    {
      appId: env.VITE_SNOWPLOW_APP_ID,
      platform: 'web',
      discoverRootDomain: true,
      eventMethod: 'post',
      cookieSecure: true,
      contexts: {
        webPage: true,
      },
      plugins: [LinkClickTrackingPlugin(), PerformanceTimingPlugin()],
    },
  )

  snowplowTracker.enableActivityTracking({
    minimumVisitLength: 10,
    heartbeatDelay: 10,
  })

  enableLinkClickTracking()

  // setup snowplow debugger on all but production builds
  // this allows to see what exactly gets tracked in the browsers js console
  if (env.VITE_MODE !== 'prod') {
    snowplowTracker.addPlugin({ plugin: DebuggerPlugin() })
  }

  // Can not extend the module snowplowTracker,
  // since it's not configurable (throws runtime error);
  // That's why we use a Proxy object instead;
  // Object.assign(snowplowTracker, {
  // trackLinkClick,
  // getCookieName: tracker.getCookieName,
  // });

  return new Proxy(snowplowTracker, {
    get(target, prop) {
      if (prop === 'trackLinkClick') {
        return trackLinkClick
      }
      if (prop === 'getCookieName') {
        return tracker.getCookieName
      }

      return target[prop]
    },
  })
})

let userContext = null

export default {
  async setDomainUserIdCookie(domainUserId) {
    if (typeof domainUserId !== 'string' || !domainUserId) {
      return
    }

    // @snowplow/browser-tracker/dist/index.umd.js
    // 2 years by default;
    const configVisitorCookieTimeout = 63072000
    const cookieName = (await getSnowplowTracker).getCookieName('id')
    let cookieValue = getCookie(cookieName)
    // If domainUserId has not been set previously then
    // we can not change/setup domainUserId,
    // since we don't know a proper sessionId.
    // sessionId is sent along the request and is
    // generated internally in Snowplow for each new session.
    // sessionId is stored inside of domainUserId cookie value;
    if (!cookieValue) {
      return
    }

    cookieValue = cookieValue.split('.')
    // domainUserId + '.' + createTs + '.' + visitCount + '.' + nowTs + '.' + lastVisitTs + '.' + sessionId;
    cookieValue[0] = domainUserId
    cookieValue = cookieValue.join('.')

    setCookie(cookieName, cookieValue, {
      path: '/',
      // from Snowplow implementation;
      expires: new Date(
        +new Date() + configVisitorCookieTimeout * 1000,
      ).toUTCString(),
      domain: window.location.hostname,
    })
  },

  setUser(user) {
    if (user instanceof User) {
      // this is temporal bypass untill we start using models in the whole codebase
      user = user._json
    }

    getSnowplowTracker.then((snowplow) => {
      snowplow.setUserId(
        (Object(user) === user &&
          ['number', 'string'].includes(typeof user.id) &&
          user.id.toString()) ||
          null,
      )
    })

    if (Object(user) !== user) {
      return
    }

    const userProfile = (Object(user.profile) === user.profile &&
      user.profile) || {
      corporate_id: null,
      age: null,
      country: null,
      gender: null,
      language: null,
      timezone: null,
      accepted_data_protection_at: null,
      accepted_tracking_at: null,
      has_disabled_emails: null,
      has_disabled_notifications: null,
      is_in_corporate_verification_phase: null,
    }
    const userState = (Object(user.state) === user.state && user.state) || {
      active_days: null,
      coach_session_scheduling_required: null,
      has_not_completed_self_test: null,
      has_pending_coach_session: null,
      is_hipaa: null,
      is_pro: null,
      body_group: null,
    }
    const userSubscription = (Array.isArray(user.subscriptions) &&
      Object(user.subscriptions[0]) === user.subscriptions[0] &&
      user.subscriptions[0]) || {
      corporate_id: null,
      expires_at: null,
      id: null,
      options: null,
      type: null,
    }
    const userMetrics = (Array.isArray(user.metrics) &&
      Object(user.metrics[0]) === user.metrics[0] &&
      user.metrics[0]) || {
      pain_value: null,
      sleep_value: null,
    }
    const userEmailDomain =
      (typeof user.email === 'string' &&
        user.email.slice(user.email.indexOf('@') + 1)) ||
      null
    const coachEnabled =
      (Object(userSubscription.options) === userSubscription.options &&
        userSubscription.options.coach_enabled) ||
      null
    const userCreatedAt = user.created_at || null
    const userPainLocation = user.pain_location || null

    userContext = {
      id: user.id,
      guid: user.guid,
      profile_email_domain: userEmailDomain,
      profile_created_at: userCreatedAt,
      profile_pain_location: userPainLocation,
      profile_corporate_id: userProfile.corporate_id,
      profile_age: userProfile.age,
      profile_country: userProfile.country,
      profile_gender: userProfile.gender,
      profile_language: userProfile.language,
      profile_timezone: userProfile.timezone,
      profile_accepted_data_protection_at:
        userProfile.accepted_data_protection_at,
      profile_accepted_tracking_at: userProfile.accepted_tracking_at,
      profile_has_disabled_emails: userProfile.has_disabled_emails,
      profile_has_disabled_notifications:
        userProfile.has_disabled_notifications,
      profile_is_in_corporate_verification_phase:
        userProfile.is_in_corporate_verification_phase,
      state_active_days: userState.active_days,
      state_coach_session_scheduling_required:
        userState.coach_session_scheduling_required,
      state_has_not_completed_self_test: userState.has_not_completed_self_test,
      state_has_pending_coach_session: userState.has_pending_coach_session,
      state_last_card_completed_at: null,
      state_is_hipaa: userState.is_hipaa,
      state_is_pro: userState.is_pro,
      state_pro_subscription_corporate_id: userSubscription.corporate_id,
      state_pro_subscription_expires_at: userSubscription.expires_at,
      state_pro_subscription_id: userSubscription.id,
      state_pro_subscription_coach_enabled: coachEnabled,
      state_pro_subscription_type: userSubscription.type,
      metrics_pain_value: userMetrics.pain_value,
      metrics_sleep_value: userMetrics.sleep_value,
      total_card_completions: null,
      bodygroup: userState.body_group,
    }
  },

  trackViewScreen(screenName, options) {
    this.trackKaiaEvent(
      {
        event_name: 'cb.lfcycl.view_screen',
        app_area: 'lifecycle',
        action: 'view',
        object_type: 'screen',
        source: 'web-checkout',
        screen_name: screenName,
      },
      options,
    )
  },

  trackCustomPayload(payload) {
    payload = Object(payload) === payload ? payload : {}
    getSnowplowTracker.then((snowplow) => {
      snowplow.trackSelfDescribingEvent({
        event: {
          schema: 'iglu:com.kaiahealth/custom_payload/jsonschema/1-0-0',
          data: {
            payload: JSON.stringify(payload),
          },
        },
      })
    })
  },

  /**
   * @param payload {Object}
   * @param payload.event_name {string} eg 'cb.onbrdg.submit_initial_assessment'
   * @param payload.app_area {string} eg 'onboarding'
   * @param payload.action {string} eg 'submit'
   * @param payload.object_type {string} eg 'initial_assessment'
   * @param payload.source {string} eg 'client_browser'
   * @param payload.screen_name {string} eg 'onb_finish_congratulations'
   * @param [options] {Object} example:
   * {
   *   custom_payload: { ... free formatted JS object }
   * }
   */
  trackKaiaEvent(payload, options) {
    payload = Object(payload) === payload ? payload : {}
    options = Object(options) === options ? options : {}

    if (typeof payload.event_name !== 'string' || !payload.event_name) {
      throw new Error(
        'VueSnowplow#trackKaiaEvent:' +
          ' expected payload.event_name to be non-empty string,' +
          ` ${payload.event_name} got instead`,
      )
    }

    const data = {
      event: {
        schema: 'iglu:com.kaiahealth/kaia_event/jsonschema/1-0-0',
        data: payload,
      },
    }

    const context = []

    if (Object(options.custom_payload) === options.custom_payload) {
      context.push({
        schema: 'iglu:com.kaiahealth/custom_payload/jsonschema/1-0-0',
        data: options.custom_payload,
      })
    }

    if (userContext != null) {
      const userPayload = JSON.parse(JSON.stringify(userContext))
      userPayload.bodygroup = parseInt(userContext.bodygroup, 10) || null
      context.push({
        schema: 'iglu:com.kaiahealth/kaia_user/jsonschema/2-1-0',
        data: userPayload,
      })
    }

    if (context.length > 0) {
      data.context = context
    }

    getSnowplowTracker.then((snowplow) => {
      snowplow.trackSelfDescribingEvent(data)
    })
  },

  trackPageView(url) {
    getSnowplowTracker.then((snowplow) => {
      snowplow.setCustomUrl(url)
      snowplow.trackPageView()
    })
  },

  /**
   * @param [log_level="info"] {"info"|"warn"|"error"}
   * @param title {string}
   * @param message {string}
   * @param [backstack=""] {string}
   * @param [tags] {Array.<string>}
   */
  trackLog(log_level = 'info', title, message, backstack = '', tags = null) {
    getSnowplowTracker.then((snowplow) => {
      snowplow.trackSelfDescribingEvent({
        event: {
          schema: 'iglu:com.kaiahealth/log/jsonschema/1-0-0',
          data: {
            title,
            log_level,
            environment: import.meta.env.VITE_SNOWPLOW_APP_ID,
            message,
            tags,
            backstack,
          },
        },
      })
    })
  },

  /**
   @param payload object, example:
   * {
   *   targetUrl: this.$route.path,
   *   elementId: 'string',
   *   elementClasses: ['string'],
   *   elementTarget: 'string',
   *   elementContent: 'string',
   * }
   */
  trackLinkClick(payload) {
    payload = Object(payload) === payload ? payload : {}
    getSnowplowTracker.then((snowplow) => {
      snowplow.trackLinkClick(payload)
    })
  },

  enableTracking() {
    // for tracking we need cookieConsent and trackingConsent to be given
    let cookieConsentAccepted = null
    try {
      cookieConsentAccepted = new Event('cookieConsentAccepted')
    } catch {
      cookieConsentAccepted = document.createEvent('Event')
      cookieConsentAccepted.initEvent('cookieConsentAccepted', false, false)
    }
    document.dispatchEvent(cookieConsentAccepted)

    let trackingAccepted = null
    try {
      trackingAccepted = new Event('trackingAccepted')
    } catch {
      trackingAccepted = document.createEvent('Event')
      trackingAccepted.initEvent('trackingAccepted', false, false)
    }

    document.dispatchEvent(trackingAccepted)
  },

  /**
   * Initialize DataDog for RUM (incl crash reporting) and log collection.
   * Proxy can be configured via environment variables to enable EU-compliant solution.
   */
  initDatadog() {
    const clientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN
    const applicationId = import.meta.env.VITE_DATADOG_APP_ID
    if (applicationId == null || clientToken == null) {
      return
    }
    const commonConfig = {
      clientToken: clientToken,
      site: 'datadoghq.com',
      service: `${import.meta.env.VITE_DISEASE}-${import.meta.env.VITE_GEO}-webcheckout`,
      env: import.meta.env.VITE_MODE,
      version: import.meta.env.VITE_VERSION,
      proxy: import.meta.env.VITE_DATADOG_PROXY,
    }
    datadogRum.init({
      ...commonConfig,
      applicationId: applicationId,
      allowedTracingUrls: [
        // trace all kaia domains and subdomains but not the snowplow collector
        /https:\/\/(?!collector\.)(?:\w+\.)?kaiahealth\.(de|com)/,
      ],
      trackUserInteractions: true,
      trackResources: import.meta.env.VITE_MODE !== 'prod',
      trackLongTasks: import.meta.env.VITE_MODE !== 'prod',
      telemetrySampleRate: 0,
      defaultPrivacyLevel: 'mask-user-input',
      sessionReplaySampleRate: 100,
      startSessionReplayRecordingManually: true,
    })
    datadogLogs.init({
      ...commonConfig,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
      forwardReports: ['intervention', 'deprecation'], // filter out csp_violations
      forwardErrorsToLogs: true,
    })
  },

  startSessionRecording() {
    datadogRum.startSessionReplayRecording()
  },

  stopSessionRecording() {
    datadogRum.stopSessionReplayRecording()
  },
}
