<template>
  <div class="reclaiming-life">
    <div class="reclaiming-life__container">
      <div class="reclaiming-life__container__header">
        <h1 class="kds-typography-display-medium">
          {{ $t('b2b__landing_page__reclaiming_life__title') }}
        </h1>
        <p class="kds-typography-paragraph-large">
          {{ $t('b2b__landing_page__reclaiming_life__description') }}
        </p>
      </div>
      <div class="reclaiming-life__container__cards">
        <div
          v-for="({ image, title, description }, index) in cards"
          :key="index"
          class="reclaiming-life__container__cards--card"
        >
          <AssetImage
            class="reclaiming-life__container__cards--card--image"
            :name="image"
          />
          <h3 class="kds-typography-title-large">
            {{ $t(title) }}
          </h3>
          <p class="kds-typography-paragraph-large">
            {{ $t(description) }}
          </p>
        </div>
      </div>
      <div class="reclaiming-life__container__cta">
        <BaseButton @click="handleCTAButtonClick"
          >{{ ctaBtnTitle }}<template #append> <ArrowRightIcon /> </template
        ></BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import AssetImage from '@shared/components/AssetImage.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import ArrowRightIcon from '@shared/assets/icon/ArrowRightIcon.vue'

defineProps({
  ctaBtnTitle: {
    type: String,
    default: '',
  },
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
})

const cards = computed(() => [
  {
    image: 'reclaiming-life-image-one.png',
    title: 'b2b__landing_page__reclaiming_life__card_one__title',
    description: 'b2b__landing_page__reclaiming_life__card_one__description',
  },
  {
    image: 'reclaiming-life-image-two.png',
    title: 'b2b__landing_page__reclaiming_life__card_two__title',
    description: 'b2b__landing_page__reclaiming_life__card_two__description',
  },
  {
    image: 'reclaiming-life-image-three.png',
    title: 'b2b__landing_page__reclaiming_life__card_three__title',
    description: 'b2b__landing_page__reclaiming_life__card_three__description',
  },
])
</script>

<style lang="scss" scoped>
.reclaiming-life {
  background: $blue-subtle;
  padding: 40px 10px;

  @include media-up(lg) {
    padding: 96px 10px;
  }

  &__container {
    @extend %container;
    margin: auto;

    &__header {
      @include flex-container(column, center, md, center);
      @include padding(xl, bottom);

      h1,
      p {
        @include padding(xs, bottom);
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-up(lg) {
        grid-gap: 48px;
        flex-direction: row;
      }

      &--card {
        @include flex-container(column, flex-start, md);

        @include media-up(lg) {
          width: 368px;
        }

        &--image {
          width: 100%;
          height: 230px;
          width: 100%;
          object-fit: cover;
          border-radius: 16px;
        }
      }
    }

    &__cta {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
  }
}
</style>
