<template>
  <div class="interactive-button-midnight">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InteractiveButtonMidnight',
}
</script>

<style lang="scss">
@import './css/InteractiveButtonMidnight.scss';
</style>
