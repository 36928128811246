<template>
  <div class="wrapper">
    <div
      :class="{
        wrapper__container: true,
        error: !!errors.length || hasErrors,
        'shrink-label': shrinkLabel,
        focused: focused && !errors.length && !hasErrors,
      }"
    >
      <div
        v-if="hasSlot['prepend']"
        class="wrapper__container--prepend"
      >
        <slot name="prepend"></slot>
      </div>
      <div class="wrapper__container--field">
        <div
          v-if="hasSlot['label']"
          class="wrapper__container--field--label"
        >
          <slot name="label" />
        </div>
        <div class="wrapper__container--field--input">
          <slot />
        </div>
      </div>
      <div
        v-if="hasSlot['append']"
        class="wrapper__container--append"
      >
        <slot name="append"></slot>
      </div>
    </div>
    <p
      v-if="focused && !errors.length && hint"
      class="wrapper__hint"
    >
      {{ hint }}
    </p>
    <div
      v-if="showErrors && errors.length"
      class="wrapper__errors"
    >
      <DangerIcon />
      <slot name="errors">
        {{ errors?.[0] ? $t(errors?.[0]) : '' }}
      </slot>
    </div>
  </div>
</template>

<script setup>
import useVueSlots from '@shared/composables/useVueSlots.js'
import DangerIcon from '@shared/assets/icon/DangerIcon.vue'

const { hasSlot } = useVueSlots(['prepend', 'append', 'label'])
defineProps({
  errors: {
    type: Array,
    default: () => [],
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  hint: {
    type: String,
    default: '',
  },
  focused: {
    type: Boolean,
  },
  shrinkLabel: {
    type: Boolean,
    required: true,
  },
})
</script>

<style lang="scss">
:root {
  --wrapper--label-padding: 13px 16px 11px 16px;
  --wrapper--label-font-size: 17px;
  --wrapper--box-shadow: 0px 0px 0px 2px #{$on-interactive};
  --wrapper--background-color: #{$subtle};
  --wrapper--label-font-weight: #{$font-weight-normal};
  --wrapper--label-color: #{$text-secondary};
  --wrapper--label-left: 0;
}

.wrapper {
  &__container {
    background-color: var(--wrapper--background-color);
    box-shadow: var(--wrapper--box-shadow);

    border-radius: 4px;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &.error {
      --wrapper--box-shadow: 0px 0px 0px 2px #{$danger};
    }

    &.shrink-label {
      --wrapper--label-padding: 4px 16px 11px 16px;
      --wrapper--label-font-size: 12px;
    }

    &.focused {
      --wrapper--box-shadow: 0px 0px 0px 2px #{$ocean};
    }

    &--field {
      position: relative;
      width: 100%;

      &--label {
        color: var(--wrapper--label-color);
        font-size: var(--wrapper--label-font-size);
        font-weight: var(--wrapper--label-font-weight);
        left: var(--wrapper--label-left);
        padding: var(--wrapper--label-padding);

        display: block;
        position: absolute;
        top: 0;
        margin: 0;
        user-select: none;
        transition: all $animation-duration;
        pointer-events: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  &__errors {
    @include margin(xs);
    @include margin(0, bottom);
    @include flex-container(row, flex-start, sm, center);
    @include kaia-typography-generic(12px, 16px, normal);

    color: $danger;
    overflow: hidden;
  }

  &__hint {
    @include margin(xs);
    @include margin(0, bottom);
    @include kaia-typography-generic(12px, 16px, normal);

    color: $midnight;
    overflow: hidden;
  }
}
</style>
