<template>
  <form
    class="content auth-content"
    @submit.prevent="resetPassword"
  >
    <div class="content-header">
      <h1 v-html="$t('b2c_question_forgot_password')" />
    </div>
    <div class="content-text">
      <p v-html="$t('b2c_verify_email')" />
    </div>
    <message-box
      v-if="message.show"
      :type="message.type"
      :title="message.title"
    >
      <p v-html="$t(message.text)" />
    </message-box>
    <div class="form-controls">
      <EmailField
        :show-errors="showErrors"
        :default-value="email"
        :autofocus="true"
        data-q-a="email"
        @input="handleTextInputChange"
      />
    </div>
    <div class="form-actions">
      <div class="interactive-button-regular">
        <InteractiveButton
          :text="$t('b2c_reset_password')"
          type="button"
          data-q-a="reset-password"
          :state="loadingButtonState"
          @click="resetPassword"
        />
      </div>
      <div class="interactive-button-outlined">
        <InteractiveButton
          :text="$t('b2c_cancel')"
          type="button"
          data-q-a="handle-cancel"
          state="ACTIVE"
          @click="goBack"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import ResetPassMedia from '@shared/components/ResetPassMedia.vue'
import MessageBox from '@shared/components/MessageBox.vue'
import { createHelpers } from '@shared/vuex-utils'
import {
  RESET_PASSWORD_MALFORMED_ERROR,
  UNKNOWN_ERROR,
} from '@msk-us/config/common-errors'
import EmailField from '@shared/components/EmailField.vue'
import InteractiveButton from '@shared/components/InteractiveButton.vue'

const { mapFields } = createHelpers({
  getterType: 'b2b/getField',
  mutationType: 'b2b/updateField',
})

export default {
  name: 'ResetPassword',
  components: {
    MessageBox,
    EmailField,
    InteractiveButton,
  },
  emits: ['update:color', 'update:media'],
  data() {
    return {
      message: {
        text: '',
        title: '',
        type: 'error',
        show: false,
      },
      emailState: false,
      showErrors: false,
      loadingButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapFields({
      email: 'formData.email',
    }),
    isFormValid() {
      return this.emailState
    },
  },
  created() {
    this.$emit('update:color', '#C7D5C4')
    this.$emit('update:media', ResetPassMedia)
  },
  methods: {
    ...mapActions({
      resetPasswordRequest: 'resetPassword',
      logoutUser: 'logout',
    }),
    resetPassword(e) {
      e.preventDefault()
      this.showErrors = false

      if (!this.isFormValid) {
        this.showErrors = true
        return false
      }

      this.loadingButtonState = 'PROCESSING'

      this.resetPasswordRequest({ email: this.email })
        .then(this.logoutUser)
        .then(this.goBack)
        .catch(this.showErrorMessage)
    },
    goBack() {
      this.loadingButtonState = 'ACTIVE'
      this.$router.back()
    },
    showErrorMessage(error) {
      this.loadingButtonState = 'ACTIVE'
      this.showErrors = false

      if (!error.response) {
        return Promise.reject(error)
      }
      if (error.response.status && error.response.status === 400) {
        this.message.text = RESET_PASSWORD_MALFORMED_ERROR
      } else {
        this.message.text = UNKNOWN_ERROR
      }
      this.message.show = true
    },
    handleTextInputChange({ name, value, state }) {
      switch (name) {
        case 'email':
          this.email = value
          this.emailState = state
          break

        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './css/ResetPassword.scss';
</style>
