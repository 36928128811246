<template>
  <div class="b2b-partners-content">
    <div class="partner-search-layout">
      <PartnerSearch
        :country="country"
        :lang="lang"
        :loading="loadingSearchResults"
        :pending-results="pendingResults"
        :corporate-key="corporateKey"
        @search="handleSearch"
        @partner-select="handlePartnerSelectedEvent"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { debounce, getCookie } from '@shared/utils'
import { isFromApp } from '@shared/helpers/isFromApp'

import { searchCorporate } from '@shared/api-endpoints'

import ROUTE from '@msk-us/router/names'

import PartnerSearch from '@msk-us/components/PartnerSearch/PartnerSearch.vue'
import VueCookieConsent from '@shared/VueCookieConsent'

const store = useStore()
const router = useRouter()
const route = useRoute()

const loadingSearchResults = ref(false)
const searchResults = ref([])
const pendingResults = ref(Promise.resolve('null'))
const corporateKey = ref(route.params?.corp_name || '')
const country = computed(() => store.getters.country)
const lang = computed(() => store.getters.lang)

const debouncedSearchCorporate = debounce((search, token, callback) => {
  callback(searchCorporate(search, '', token))
}, 250)

let searchRequestAbortController
const handleSearch = (searchTerm) => {
  loadingSearchResults.value = false
  searchResults.value = []

  if (searchRequestAbortController) {
    searchRequestAbortController.abort(
      'Cancel corporate search as newer search request is fired',
    )
  }

  searchRequestAbortController = new AbortController()

  loadingSearchResults.value = true

  debouncedSearchCorporate(
    searchTerm,
    searchRequestAbortController.signal,
    (corporates) => {
      pendingResults.value = corporates
      loadingSearchResults.value = false
    },
  )
}

const handlePartnerSelectedEvent = (partner) => {
  router.push({
    name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
    params: {
      corp_name: partner.key,
    },
  })
}

const consentCookie = getCookie('CookieConsent')
if (consentCookie === null) {
  VueCookieConsent(window, document, consentCookie, '', isFromApp(), lang.value)
}
</script>

<style lang="scss">
.b2b-partners-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content {
    padding-bottom: 60px;
  }

  .content-header {
    padding-top: 50px;
    display: block;
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  p {
    font-size: 17px;
    line-height: 22px;
  }
}

.partner-search-layout {
  height: 100%;
  padding: 100px 82px 60px;

  @media (max-width: $breakpoint-tablet) {
    padding: 0;
    padding-bottom: 24px;
  }
}
</style>
