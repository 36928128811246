<template>
  <div class="verify-not-possible-for-existing-user-content">
    <MobileHeader />
    <div class="content">
      <CorporateHeader />
      <div class="content-header">
        <p
          class="kaia-p3-bold"
          v-html="$t('b2b_verify_not_possible_for_existing_user_title')"
        />
      </div>
      <p v-html="$t('b2b_verify_not_possible_for_existing_user_message')" />
      <div class="verify-not-possible-for-existing-user-actions">
        <button
          type="button"
          class="btn btn-primary"
          @click="openSignUp"
          v-html="$t('b2b_verify_not_possible_for_existing_user_action_signup')"
        />
        <button
          type="button"
          class="btn btn-primary"
          @click="openB2C"
          v-html="$t('b2b_verify_not_possible_for_existing_user_action_b2c')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MobileHeader from '@shared/components/MobileHeader.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import ROUTE from '@msk-us/router/names'

const { mapGetters: mapB2BGetters } = createNamespacedHelpers('b2b')

export default {
  name: 'B2BVerifyNotPossibleForExistingUser',
  components: { MobileHeader, CorporateHeader },
  computed: {
    ...mapB2BGetters(['corporate', 'verificationParams']),
  },
  methods: {
    openSignUp() {
      this.$router.push({
        name: ROUTE.B2B_SIGN_UP,
        query: {
          ...this.verificationParams,
          corp_name: this.corporate.key,
          login: 'false',
        },
      })
    },
    openB2C() {
      this.$router.push({
        name: ROUTE.SIGN_IN,
      })
    },
  },
}
</script>

<style lang="scss">
.verify-not-possible-for-existing-user-content {
  .content-header {
    margin-top: 60px;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
  }

  p {
    font-size: 17px;
    line-height: 22px;
  }

  .verify-not-possible-for-existing-user-actions {
    margin-top: 60px;

    button {
      width: 100%;
      margin-top: 25px;
    }
  }
}
</style>
