<template>
  <div />
</template>

<script>
import lottie from 'lottie-web/build/player/esm/lottie.min.js'

export default {
  name: 'LottieAnimationContainer',
  props: {
    jsonConfigPath: {
      type: String,
      default: '',
    },
    renderer: {
      type: String,
      default: 'svg',
    },
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lottieAnimationObject: null,
    }
  },
  mounted() {
    this.lottieAnimationObject = lottie.loadAnimation({
      container: this.$el,
      path: this.jsonConfigPath,
      renderer: this.renderer,
      loop: this.loop,
      autoplay: this.autoplay,
    })
  },
  unmounted() {
    if (this.lottieAnimationObject != null) {
      this.lottieAnimationObject.destroy()
      this.lottieAnimationObject = null
    }
  },
}
</script>
