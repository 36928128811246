import { PublicApi, PrivateApi } from '@shared/api-client'

export const sendUserFeedback = ({
  firstName,
  lastName,
  phoneNumber,
  usState,
  dateOfBirth,
  spouse,
  language,
  country,
  timezone,
  acceptedDataProtection,
  corporateId,
  stayLoggedIn,
}) => {
  const feedback = [
    { key: 'first_name', value: firstName },
    { key: 'last_name', value: lastName },
    { key: 'phone_number', value: phoneNumber },
    { key: 'us_state', value: usState },
    { key: 'date_of_birth', value: dateOfBirth },
    { key: 'spouse', value: spouse },
    { key: 'language', value: language },
    { key: 'country', value: country },
    { key: 'timezone', value: timezone },
    { key: 'accepted_data_protection', value: acceptedDataProtection },
    { key: 'corporate_id', value: corporateId },
    { key: 'stay_logged_in', value: stayLoggedIn },
  ].filter((entry) => entry.value != null)
  return PrivateApi.post('user/add_feedback/', {
    feedback,
  })
}

export const subscribeB2BUserWithVoucher = ({ code }) =>
  PrivateApi.post('payment/subscribe_with_corporate_voucher', {
    code,
  })

export const checkVoucherB2b = (code) =>
  PublicApi.post('check_corporate_voucher/', { code })

export const checkEmailB2b = (email, corporate) =>
  PublicApi.get(
    `check_autosubscribe_address?email=${encodeURIComponent(
      email,
    )}&corporate_key=${encodeURIComponent(corporate)}`,
  )

export const getCorporate = (name, lang, id) =>
  id == null
    ? PublicApi.get(`content/get_corporate?key=${name}&language=${lang}`)
    : PublicApi.get(`content/get_corporate?id=${id}&language=${lang}`)

export const getUser = () => PrivateApi.get('user/get_user')

export const setUserProfile = ({
  firstName,
  acceptedDataProtection,
  phoneNumber,
  dateOfBirth,
  usState,
  corporateId,
}) =>
  PrivateApi.post('user/set_profile', {
    first_name: firstName,
    accepted_data_protection: acceptedDataProtection,
    ...(phoneNumber && { phone_number: phoneNumber }),
    ...(dateOfBirth && { date_of_birth: dateOfBirth }),
    ...(usState && { us_state: usState }),
    ...(corporateId && { corporate_id: corporateId }),
  })

export const setUserEmail = (email) =>
  PrivateApi.post('user/set_email', { email })

export const getCorporateEligibilityConfig = (corporateKey, lang) =>
  PublicApi.get(
    `corporates/${corporateKey}/eligibility_config?language=${lang}`,
  )

export const checkCorporateEligibility = (corporateEligibilityData) =>
  PublicApi.get('check_corporate_eligibility', {
    params: corporateEligibilityData,
  })

export const getCorporateEligibilityGroupConfig = (groupId, lang) =>
  PublicApi.get(
    `corporate_eligibility_groups/${groupId}/eligibility_config?language=${lang}`,
  )

export const checkCorporateGroupEligibility = (corporateGroupEligibilityData) =>
  PublicApi.get('check_corporate_group_eligibility', {
    params: corporateGroupEligibilityData,
  })

/**
 * @param { string } locale of the faqs, 'en-US' or 'es-US'
 * @typedef { id: string, title: string, order: number, text: string} Faq
 * @typedef { count: number, faqs: Faq[] } FaqList
 * @returns { Promise<import("axios").AxiosResponse<{ count: number, faqs: Faq[] }>> }: count of faqs and list of faqs
 */
export const getHelpscoutFaqs = async (locale) =>
  await PublicApi.get('support/faqs', {
    params: { locale },
  })

export const submitSupportTicket = async (data) =>
  await PublicApi.post('support/tickets', data)

export const submitSelfTest = (data) =>
  PrivateApi.post('user/complete_self_test', data)

export const submitSelfTestReevaluations = (data) =>
  PrivateApi.post('user/assessment_questionnaire_results', {
    assessment_questionnaire_results: {
      self_test_reevaluation: data,
    },
  })

export const submitCAT = (data) =>
  PrivateApi.post('user/assessment_questionnaire_results', {
    assessment_questionnaire_results: {
      cat: data,
    },
  })

export const getCheckups = () => PrivateApi.get('user/get_checkups')

export const searchCorporate = (search, country, abortSignal) =>
  PublicApi.get('search_corporate', {
    params: { search, ...(country ? { country } : {}) },
    signal: abortSignal,
  })

export const subscribeWithInsuranceToken = (corporateKey, token, type) =>
  PrivateApi.post('payment/subscribe_with_insurance_token', {
    corporate_name: corporateKey,
    token,
    type,
  })

export const subscribeWithEligibleToken = (eligible_token) =>
  PrivateApi.post('payment/subscribe_with_eligible_token', {
    eligible_token,
  })

export const sendBrazeEvent = ({ name, properties }) => {
  if (name == null) {
    return Promise.reject({
      id: 1,
      message: `sendBrazeEvent: expected ${name} to be a string;`,
    })
  }

  const payload = { name }

  if (properties != null) {
    payload.properties = properties
  }

  return PrivateApi.post('user/send_crm_event', payload)
}
