<template>
  <InputWrapper
    :shrink-label="!!model"
    :errors="errors"
  >
    <template #label>{{ label }}</template>
    <div class="select-field">
      <select
        :id="id"
        ref="selectTag"
        v-model="model"
        class="select-field__select"
        :data-qa="dataQa"
        v-bind="$attrs"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @click="handleClick"
      >
        <option
          v-for="({ text, attrs }, index) in options"
          :key="index"
          class="select-field__select--option"
          v-bind="attrs"
        >
          {{ text }}
        </option>
      </select>
      <ArrowDownIcon
        :class="{
          'select-field__activator-icon': true,
          close: !focused,
          open: focused,
        }"
      />
    </div>
  </InputWrapper>
</template>

<script setup>
import { ref } from 'vue'
import ArrowDownIcon from '@shared/assets/icon/ArrowDownIcon.vue'
import InputWrapper from './InputWrapper.vue'

const focused = ref(false)

defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits(['blur', 'focus', 'change', 'click'])
defineProps({
  errors: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  /**
    Options: { text: string, selectedText: string, value: any }[]
  */
  options: {
    type: Array,
    default: () => [],
  },
  id: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  dataQa: {
    type: String,
    default: '',
  },
})

const selectTag = ref(null)
const model = defineModel({ default: '', type: String })

const handleFocus = () => {
  focused.value = true
  emit('focus')
}

const handleBlur = () => {
  focused.value = false
  emit('blur')
}

const handleChange = () => {
  focused.value = false
  selectTag.value.blur()
  emit('change')
}

const handleClick = () => {
  focused.value = !focused.value

  emit('click')
}
</script>

<style lang="scss">
:root {
  --select-field--padding: 20px 16px 4px 16px;
}

.wrapper {
  &__container {
    @include grid-container(1fr);

    &--field {
      &--input {
        .select-field {
          width: 100%;
          &__select {
            @include kaia-typography-p2($font-weight-semi-bold);

            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            width: 100%;
            padding: var(--select-field--padding);

            color: $midnight;
            border-radius: 4px;
            border: none;
            outline: none;
            box-sizing: border-box;
            height: 100%;
          }

          &__activator-icon {
            position: absolute;
            right: 10px;
            top: 45%;

            &.open {
              -webkit-animation: open 0.25s forwards;
              -moz-animation: open 0.25s forwards;
              animation: open 0.25s forwards;
            }

            &.close {
              -webkit-animation: close 0.25s forwards;
              -moz-animation: close 0.25s forwards;
              animation: close 0.25s forwards;
            }
          }
        }
      }
    }
  }
}

@-moz-keyframes open {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(180deg);
  }
}

@-webkit-keyframes open {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes open {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@-moz-keyframes close {
  0% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes close {
  0% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes close {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>
