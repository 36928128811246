import env from '@shared/env'
import { getOneTimeAuthToken } from '@shared/api-endpoints'
import { isFromApp } from '@shared/helpers/isFromApp'

/**
 * Assemble a deeplink for the Kaia Apps
 * @param [path='open'] {string}
 * @param [query={}] {Object.<string, string | number | boolean>} the key <> value pairs of the query, whereas the values will be casted to string and URI encoded
 * @param [addOneTimeAuthToken=false] {boolean} if true loads a oneTimeAuthToken from the backend and adds it to the query. IMPORTANT: if that fails it does not return the normal requested deeplink but instead just returns a simple deeplink that opens the app. Additionally, in case a token can be added it will (force) use URI scheme
 * @param [forceURIScheme=false] {boolean}
 * @throws when `addOneTimeAuthToken` is true but appending the token failed (eg due to api request failing or response being malformed)
 * @returns {Promise<string>}
 */
const assembleKaiaAppDeepLink = async (
  path = 'open',
  query = {},
  addOneTimeAuthToken = false,
  forceURIScheme = false,
) => {
  if (addOneTimeAuthToken) {
    const getTokenResponse = await getOneTimeAuthToken()
    const token = getTokenResponse?.data?.token
    if (!token?.length > 0) throw 'no valid one_time_auth_token'
    query.token = token
    forceURIScheme = true
  }

  const scheme =
    forceURIScheme || isFromApp()
      ? env.VITE_DEEP_LINK
      : env.VITE_KAIA_APP_DEEP_LINK

  const url = new URL(scheme + path)

  // URL.searchParams.append will automatically url encode the values
  Object.entries(query).forEach(([key, value]) =>
    url.searchParams.append(key, value.toString()),
  )

  return url.toString()
}

export default assembleKaiaAppDeepLink
