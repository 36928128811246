import Bus from './bus'

export default {
  open(options = null) {
    Bus.$emit('new', options)
  },

  close(data = null) {
    Bus.$emit('close', data)
  },
}

export { Bus }
