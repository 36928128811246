<template>
  <modal
    type="content-with-iframe"
    @close="closeModal"
  >
    <template #body>
      <div
        v-if="iframePreloader"
        class="iframe-preloader"
      >
        <Spinner>{{ $t('generic_loading') }}</Spinner>
      </div>
      <iframe
        class="modal-iframe"
        :src="documentUrl"
        @load="iframeLoaded"
      ></iframe>
    </template>
  </modal>
</template>

<script>
import $modals from '@shared/modal/index'
import Modal from './Modal.vue'
import Spinner from './Spinner.vue'

export default {
  name: 'IframeModal',
  components: { Modal, Spinner },
  props: {
    documentUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iframePreloader: false,
    }
  },
  mounted() {
    this.iframePreloader = true
    this.iframe = this.$el.querySelector('iframe')
  },
  unmounted() {
    this.iframePreloader = false
    $modals.close()
  },
  methods: {
    closeModal() {
      $modals.close()
    },
    iframeLoaded() {
      this.iframePreloader = false
      this.iframe.contentDocument.addEventListener('click', (e) => {
        if (e.__clickDelegatedToRootNode__) {
          e.preventDefault()

          const tag = document.createElement('a')
          tag.setAttribute('href', e.target.href)
          tag.click()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-container {
  &.content-with-iframe {
    position: relative;
    max-width: 800px;
    max-height: 810px;
    width: 100%;
    height: 70vh;
    margin: 0 auto;
    padding: 50px 30px 0;
    box-sizing: border-box;
    .modal-header {
      position: absolute;
      right: 0;
      top: 0;
      &-close-btn {
        z-index: 1;
        position: relative;
        top: 50px;
        right: 50px;
      }
    }
    .modal-body {
      height: 100%;
      margin: 0;
    }
  }
}
.modal-iframe {
  border: none;
  width: 100%;
  height: 100%;
}
.iframe-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-height: 720px) {
  .modal-container {
    &.content-with-iframe {
      height: 100vh;
    }
  }
}
@media (max-width: 860px) {
  .modal-container {
    &.content-with-iframe {
      border-radius: 0;
      padding: 50px 0 0;
      width: 100vw;
      height: 100vh;
      max-width: 100vh;
      max-height: 100vh;
      box-sizing: border-box;
      .modal-header {
        &-close-btn {
          top: 24px;
          right: 24px;
        }
      }
      .modal-body {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}
</style>
