<template>
  <div class="self-test-reevaluations-layout">
    <AssessmentNavigation
      :stage-progress="stageProgress"
      :stage-count="stageCount"
      :stage="stage"
      :title="title"
      :back-button="backButton"
      @back="goToPreviousRoute"
    />
    <router-view :handle-next-event="goToNextOrCompleteQuestionnaire" />
  </div>
</template>

<script>
import B2bMedia from '@shared/components/B2bMedia.vue'
import selfTestReevaluationsNavigation from '@msk-us/mixins/selfTestReevaluationsNavigation'
import AssessmentNavigation from '@shared/components/AssessmentNavigation.vue'
import ROUTE from '@msk-us/router/names'

export default {
  name: 'SelfTestReevaluations',
  components: {
    AssessmentNavigation,
  },
  mixins: [selfTestReevaluationsNavigation],
  data() {
    return {
      media: B2bMedia,
    }
  },
  computed: {
    title() {
      return this.$t('b2b_self_test_generic_assessment')
    },
    backButton() {
      return (
        this.$route.name !==
        ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE
      )
    },
  },
}
</script>

<style lang="scss">
.self-test-reevaluations-layout {
  height: 100%;

  .self-test-navigation {
    position: absolute;
    left: 0;
    right: 0;

    .quiz-nav-header__title {
      margin: 0 auto;
      text-align: center;
    }
  }

  &--no-progress-bar .quiz-nav-progress {
    display: none;
  }
}
</style>
