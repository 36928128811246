<template>
  <div class="interactive-button-white">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InteractiveButtonWhite',
}
</script>

<style lang="scss">
@import './css/InteractiveButtonWhite.scss';
</style>
