<template>
  <transition
    name="vertical-show-hide"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
  >
    <div
      v-if="visible"
      ref="contentRef"
      class="vertical-show-hide__inner"
    >
      <slot />
    </div>
  </transition>
</template>
<script setup>
import { ref } from 'vue'

defineProps({
  visible: Boolean,
})

const contentRef = ref(null)

const beforeEnter = (el) => {
  el.style.height = '0'
}

const enter = (el, done) => {
  // get the height of the content and set is as the animation target height
  if (contentRef.value) {
    el.style.height = `${contentRef.value.scrollHeight}px`
    el.addEventListener('transitionend', done, { once: true })
  }
}

const leave = (el, done) => {
  el.style.height = '0'
  el.addEventListener(
    'transitionend',
    () => {
      // Reset to auto for responsiveness after transition
      el.style.height = 'auto'
      done()
    },
    { once: true },
  )
}
</script>

<style lang="scss">
.vertical-show-hide-enter-active,
.vertical-show-hide-leave-active {
  // give it a bit of a delay so that clicks are still executed with the old bounding boxes
  transition: height 0.2s ease-in-out 0.1s;
}
.vertical-show-hide__inner {
  overflow: hidden;
}
</style>
