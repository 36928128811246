<template>
  <text-input
    input-name="password"
    :input-label="inputLabel || $t('generic_field_password')"
    :state="stateComputed"
    :default-value="defaultValue"
    :autocomplete="false"
    :visibility="visibility"
    :autofocus="autofocus"
    :error-message="errorMessageComputed"
    :error-code="errorCodeComputed"
    :data-q-a="dataQA"
    @input="handleInputEvent"
  />
</template>

<script>
import TextInput from '@shared/components/TextInput.vue'

const ERRORS = {
  EMPTY: 1,
  MIN_LENGTH: 2,
  CASE_CHARS: 3,
  MUST_HAVE_DIGIT_OR_SYMBOL: 4,
  CUSTOM: 5,
}

const getErrorCode = (value) => {
  if (!value) {
    return ERRORS.EMPTY
  }
  if (value.length < 8) {
    return ERRORS.MIN_LENGTH
  }
  if (!/(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
    return ERRORS.CASE_CHARS
  }
  if (!/(?=.*[^a-zA-Z])/.test(value)) {
    return ERRORS.MUST_HAVE_DIGIT_OR_SYMBOL
  }

  return 0
}

export default {
  name: 'PasswordField',
  components: {
    TextInput,
  },
  inheritAttrs: false,
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    visibility: {
      type: Boolean,
      default: true,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    dataQA: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      nextValue: '',
    }
  },
  computed: {
    errorMessageComputed() {
      switch (this.errorCodeComputed) {
        case ERRORS.EMPTY:
          return this.$t('generic_field_required', {
            fieldTitle: this.$t('generic_field_password'),
          })

        case ERRORS.MIN_LENGTH:
          return this.$t('generic_password_at_least_8_characters')

        case ERRORS.CASE_CHARS:
          return this.$t(
            'generic_password_at_least_1_upper_and_1_lower_case_letter',
          )

        case ERRORS.MUST_HAVE_DIGIT_OR_SYMBOL:
          return this.$t('generic_password_at_least_1_digit')

        default:
          return ''
      }
    },
    stateComputed() {
      return this.errorCodeComputed === 0
    },
    errorCodeComputed() {
      if (this.showErrors) {
        return getErrorCode(this.nextValue)
      }

      return 0
    },
  },
  created() {
    this.nextValue = this.defaultValue

    this.$emit('input', {
      name: 'password',
      value: this.nextValue,
      state: getErrorCode(this.nextValue) === 0,
    })
  },
  methods: {
    handleInputEvent(event) {
      this.nextValue = event.value.trim()
      const nextErrorCode = getErrorCode(this.nextValue)

      this.$emit('input', {
        name: event.name,
        value: this.nextValue,
        state: nextErrorCode === 0,
      })
    },
  },
}
</script>
