import axios from 'axios'

import { logI, logW } from '@shared/utils'

/**
 * Api instance for unauthenticated endpoints (and session creation):
 * - does not populate auth tokens into the headers
 * - pulls auth tokens from response headers if present
 * @type {AxiosInstance}
 */
const PublicApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL}/v2`,
})

/**
 * Api instance for authenticated endpoints:
 * - populates auth tokens into request headers
 * - pulls auth tokens from response headers if present
 * - rotates auth tokens if required
 * @type {AxiosInstance}
 */
const PrivateApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL}/v2`,
})

/**
 * Extract the authentication token from the query and store it as the session authentication
 * so that follow-up endpoint calls can automatically be authenticated.
 * This is only used for VP SSO.
 * @param {Store} store - VueX store used to store the auth tokens
 * @param {Object} query - query that contains the token for authentication
 */
// todo this needs to be refactored as we currently would not get a refresh-token this way
export function saveAuthQueryToStore(store, query) {
  // do nothing if query is not an object that has a member `token`
  if (!('token' in (query || {}))) {
    return
  }
  const authHeaders = {
    'access-token': query.token,
    client: query.client_id,
    guid: query.guid,
  }
  store.commit('auth', authHeaders)
}

function getAuthHeadersFromStore(store) {
  const headers = store.getters.auth
  if (headers == null) {
    return {}
  }

  return {
    ...(headers['access-token'] == null
      ? {}
      : { 'access-token': headers['access-token'] }),
    ...(headers['token-type'] == null
      ? {}
      : { 'token-type': headers['token-type'] }),
    ...(headers.client == null ? {} : { client: headers.client }),
    ...(headers.guid == null ? {} : { guid: headers.guid }),
  }
}

function saveAuthHeadersToStore(store, response) {
  const { headers } = response
  if (!('access-token' in headers)) {
    return
  }
  if (
    headers['access-token'] === getAuthHeadersFromStore(store)['access-token']
  ) {
    return
  }
  const authHeaders = {
    'access-token': headers['access-token'],
    'token-type': headers['token-type'],
    'refresh-token': headers['refresh-token'] || '',
    client: headers.client,
    expiry: headers.expiry,
    guid: headers.guid,
  }
  store.commit('auth', authHeaders)
}

function assembleClientHeaders(store) {
  const appId = `kaia-${import.meta.env.VITE_DISEASE}-WebCheckout`
  const clientVersion = import.meta.env.VITE_VERSION
  const userAgent = `${appId}-${clientVersion}`
  const { clientAppId: hostClientAppId, clientVersion: hostClientVersion } =
    store.getters.hostAppIdentifiers
  const defaultHostIdentifier = 'unset' // indicates that the WebCheckout app is not rendered in a webview

  return {
    'x-app-id': appId,
    'x-user-agent': userAgent,
    'x-client-version': clientVersion,
    'x-host-client-app-id': hostClientAppId || defaultHostIdentifier,
    'x-host-client-version': hostClientVersion || defaultHostIdentifier,
  }
}

/**
 * Request a new token using the refresh token if the current token is expired.
 * The response interceptor from the PublicApi will store the new auth information
 * before the original request (on the PrivateApi) is resumed and pulls them from the store
 * @param {Store} store - VueX store used to store the auth tokens
 * @returns {Promise<AxiosResponse<any>>}
 */
function refreshTokenIfNeeded(store) {
  const today = new Date()
  const headers = store.getters.auth || {}
  if ('refresh-token' in headers && today.getTime() >= headers.expiry * 1000) {
    return PublicApi.post('auth/refresh', null, {
      headers: {
        'refresh-token': headers['refresh-token'],
        client: headers.client,
        guid: headers.guid,
      },
    })
  }
}

function logError(error) {
  if (axios.isCancel(error)) {
    // drop the error silently if the request was canceled
    logI('request canceled', error.message)
  } else {
    // dont send full error to prevent PHI from being leaked
    logW(error.message, error.config && error.config.url)
  }
}

function initPublicApi(store) {
  PublicApi.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      ...assembleClientHeaders(store),
    }
    return config
  })

  PublicApi.interceptors.response.use(
    (response) => {
      saveAuthHeadersToStore(store, response)
      return response
    },
    (error) => {
      logError(error)
      return Promise.reject(error)
    },
  )
}

function initPrivateApi(store) {
  PrivateApi.interceptors.request.use(async (config) => {
    await refreshTokenIfNeeded(store)
    config.headers = {
      ...config.headers,
      ...getAuthHeadersFromStore(store),
      ...assembleClientHeaders(store),
    }
    return config
  })

  PrivateApi.interceptors.response.use(
    (response) => {
      saveAuthHeadersToStore(store, response)
      return response
    },
    (error) => {
      logError(error)
      return Promise.reject(error)
    },
  )
}

/**
 * Initialize the API layer
 * Needs to be called at the start of the Vue application
 * @param {Store} store - VueX store used to store the auth tokens
 */
export const initApi = (store) => {
  initPublicApi(store)
  initPrivateApi(store)
}

export { PublicApi, PrivateApi }
