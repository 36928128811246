<template>
  <transition name="modal">
    <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
    <div class="modal-mask">
      <div
        class="modal-wrapper"
        @click="$emit('close', $event)"
      >
        <div :class="['modal-container', type]">
          <div class="modal-header">
            <slot name="header"></slot>
            <button
              type="button"
              class="modal-header-close-btn"
              @click="$emit('close', $event)"
            ></button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    type: {
      type: String,
      default: 'confirm',
    },
  },
  emits: ['close'],
  mounted() {
    this.toggleBodyClass('addClass', 'overflow')
  },
  unmounted() {
    this.toggleBodyClass('removeClass', 'overflow')
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body

      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$transition-duration: 0.1s;

.modal-mask,
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-mask {
  z-index: 9998;
  background-color: rgba(33, 34, 38, 0.8);
  transition: opacity $transition-duration ease;
}
.modal-wrapper {
  z-index: 9999;
  box-sizing: border-box;
  padding: 5vw;
  overflow-y: scroll;
  display: flex;
}
.modal-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; // Make sure overfull containers are position correctly inside the scrolling wrapper. Avoids https://stackoverflow.com/questions/33454533/

  background-color: #fff;
  border-radius: 16px;
  transition: all $transition-duration ease;
  box-sizing: border-box;
  overflow: auto; // Avoid bleeding pixels from content's background on odd window widths.
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    justify-content: flex-start;
  }

  h3 {
    font-size: 31px;
    line-height: 36px;
    margin-bottom: 0;
    margin-top: 30px;
  }
  &-close-btn {
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: $sky;
    background-image: url('@shared/assets/img/close-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      position: static;
      margin-left: auto;
    }
  }
}

.modal-body {
  margin: 16px 0 48px;
  height: 100%;
  p {
    margin-top: 16px;
    margin-bottom: 5px;
    font-size: 17px;
    line-height: 24px;
    color: #9da1a6;
  }
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(0.9);
}
@media (max-width: 860px) {
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: none;
  }
}
</style>
