import AbstractModel from './utils/abstractModel'
import types from './utils/types'

export default class UserState extends AbstractModel {
  /**
   * @type {number}
   */
  id

  /**
   * @type {boolean}
   */
  isPro

  /**
   * @type {number,null}
   */
  activeDays

  /**
   * @type {boolean,null}
   */
  coachSessionSchedulingRequired

  /**
   * @type {boolean}
   */
  hasNotCompletedSelfTest

  /**
   * @type {boolean,null}
   */
  hasPendingCoachSession

  /**
   * @type {boolean}
   */
  isHipaa

  constructor(json) {
    super(json)
    this.id = json.id
    this.isPro = json.is_pro
    this.activeDays = json.active_days
    this.coachSessionSchedulingRequired = json.coach_session_scheduling_required
    this.hasNotCompletedSelfTest = json.has_not_completed_self_test
    this.hasPendingCoachSession = json.has_pending_coach_session
    this.isHipaa = json.is_hipaa

    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('isPro', [types.BOOLEAN])
    this.assertType('activeDays', [types.NUMBER, types.NULL])
    this.assertType('coachSessionSchedulingRequired', [
      types.BOOLEAN,
      types.NULL,
    ])
    this.assertType('hasNotCompletedSelfTest', [types.BOOLEAN])
    this.assertType('hasPendingCoachSession', [types.BOOLEAN, types.NULL])
    this.assertType('isHipaa', [types.BOOLEAN])
  }
}
