<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="partner-search">
    <div class="partner-search__top-layout">
      <div
        v-if="showBanner"
        class="partner-search__banner"
      />
      <div class="partner-search__intro-layout">
        <PartnerSearchIntro :country="country" />
      </div>
      <PartnerSearchCtrl
        :pending-results="pendingResults"
        :corporate-key="corporateKey"
        :loading="loading"
        @search="handlePartnerSearch"
        @ctrl-state-change="handlePartnerSearchCtrlState"
      />

      <div
        v-if="showNotFoundWarningBox"
        class="partner-search__not-found-warning js-not-found-warning"
      >
        <BaseBanner
          variant="important"
          :title="$t('b2b__partner_search__not_found_warning__header')"
          class="not-found"
        >
          <p
            v-html="$t('b2b__partner_search__not_found_warning__description')"
          />
        </BaseBanner>
      </div>
    </div>
    <div class="partner-search__bottom-layout">
      <SupportEmail class="partner-search__support-email" />
      <div class="partner-search__language-layout">
        <LanguageSwitcher
          :disabled="ctrlItemDisabled"
          @change="handleLanguageChange"
        />
      </div>
    </div>
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed } from 'vue'
import { isMobile } from '@shared/utils'
import { isFromApp } from '@shared/helpers/isFromApp'

import PartnerSearchCtrl from '@msk-us/components/PartnerSearch/PartnerSearchCtrl.vue'
import SupportEmail from '@shared/components/SupportEmail.vue'
import PartnerSearchIntro from '@msk-us/components/PartnerSearch/PartnerSearchIntro.vue'
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'

const emit = defineEmits(['partnerSelect', 'search'])
defineProps({
  country: {
    type: String,
    default: 'US',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  pendingResults: {
    type: Object,
    default: () => Promise.resolve('null'),
  },
  corporateKey: {
    type: String,
    default: () => '',
  },
})

const showNotFoundWarningBox = ref(false)
const ctrlItemDisabled = ref(false)

const showBanner = computed(() => {
  return isMobile() && !isFromApp()
})

const handleLanguageChange = () => {
  showNotFoundWarningBox.value = false
}

const handlePartnerSearch = (value) => {
  if (!value || value.length <= 2) {
    return
  }

  emit('search', value.trim())
}

const handlePartnerSearchCtrlState = ({ state, value }) => {
  ctrlItemDisabled.value = false

  switch (state) {
    case 'active':
      showNotFoundWarningBox.value = false
      break

    case 'disabled':
      showNotFoundWarningBox.value = true
      break

    case 'processing':
      ctrlItemDisabled.value = true
      break

    case 'validated':
      ctrlItemDisabled.value = true
      emit('partnerSelect', value)
      break

    default:
      break
  }
}
</script>

<style lang="scss">
.partner-search {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  &__support-email {
    @media (max-width: $breakpoint-tablet) {
      padding: 0 24px;
    }
  }

  &__fields_error {
    margin-bottom: 17px;
  }

  &__support_link {
    color: $ocean;
    text-decoration: none;
    padding-left: 40px;
    @include kaia-typography-p2($font-weight-semi-bold);
  }

  &__banner {
    display: none;
    background-color: $sky;
    background-image: url('@shared/assets/img/kaia_text_logo.svg');
    background-position: 24px center;
    background-repeat: no-repeat;
    background-size: auto 30%;
    padding: 32px;
  }

  &__language-layout {
    margin-top: 40px;
  }

  &__not-found-warning {
    margin-top: 24px;
  }

  &__not-found-support {
    margin-bottom: 7px;
    font-weight: bolder;
  }

  &__confirmation {
    background-color: $yellow-light;
    background-image: url('@shared/assets/img/b2b/icon-check-quiz.svg');
    background-position: 26px 30px;
    background-repeat: no-repeat;

    margin-top: 24px;
    padding: 28px 19px 28px 62px;

    &-header {
      margin: 0;

      @include kaia-typography-p2($font-weight-bold);
    }
  }

  @media (max-width: $breakpoint-tablet) {
    &__not-found-warning {
      padding: 0 24px;
    }

    &__language-layout {
      padding: 0 24px;
    }

    &__intro-layout {
      margin-top: 20px;
    }

    &__banner {
      display: block;
    }

    &__top-layout {
      margin-bottom: 24px;
    }

    &__confirmation {
      padding: 24px;

      &-header {
        padding-left: 40px;
      }
    }
  }
}
</style>
