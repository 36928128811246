<template>
  <div class="faqs">
    <h2
      v-if="items.length"
      class="faqs__title"
    >
      {{ $t('b2b_static_frequently_asked_questions_title') }}
    </h2>
    <Accordion
      :items="items"
      @click="handleAccordionClick"
    />
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getHelpscoutFaqs } from '@shared/api-endpoints/b2b'
import Tracker from '@shared/Tracker'

import Accordion from '@shared/components/Accordion.vue'

const route = useRoute()
const store = useStore()

const items = ref([])
const lang = computed(() => store.state.lang)

const handleAccordionClick = ({ open, index }) => {
  Tracker.trackLinkClick({
    targetUrl: route.path,
    elementId: open ? 'faq-expanded' : 'faq-collapsed',
    elementClasses: [],
    elementTarget: '',
    elementContent: items.value[index].title,
  })
}

watchEffect(async () => {
  const { data } = await getHelpscoutFaqs(lang.value)
  items.value =
    data?.faqs?.map(({ title, text }) => ({ title, content: text })) ?? []
})
</script>

<style lang="scss">
.faqs {
  padding: 56px 24px 0;
  @include padding(xxl, y);
  max-width: 1000px;
  margin: auto;

  &__title {
    @include kaia-typography-h5($font-weight-bold);
    @include margin(lg, bottom);
    text-align: center;
  }
}
</style>
