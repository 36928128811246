import { h } from 'vue'
import Bus from './bus'

export default {
  name: 'vu-modal-wrapper',
  data() {
    return {
      modal: null,
    }
  },
  created() {
    const scrollContainer =
      document.scrollingElement || document.documentElement
    let scrollContainerStylesWithoutModal = {}

    Bus.$on('new', (options) => {
      const defaults = {
        onClose() {},
      }
      this.modal = Object.assign(defaults, options)

      const scrollbarWidth = window.innerWidth - scrollContainer.clientWidth

      scrollContainerStylesWithoutModal = {
        paddingRight: scrollContainer.style.paddingRight,
        overflowY: scrollContainer.style.overflowY,
      }

      Object.assign(scrollContainer.style, {
        paddingRight: `${scrollbarWidth}px`,
        overflowY: 'hidden',
      })
    })

    Bus.$on('close', (data) => {
      Object.assign(scrollContainer.style, scrollContainerStylesWithoutModal)
      this.close(data)
    })
  },
  methods: {
    close() {
      this.modal = null
    },
  },
  computed: {
    body() {
      if (typeof document !== 'undefined') {
        return document.querySelector('body')
      }
    },
  },
  render() {
    if (!this.modal) {
      return null
    }

    const modalComponent = h(this.modal.component, { ...this.modal.props })

    return h(
      'div',
      {
        class: ['root-modal'],
        // on: { click: () => { this.close(); } },
      },
      [modalComponent],
    )
  },
}
