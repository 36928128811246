<template>
  <modal
    type="disclaimer-modal"
    @close="closeModal"
  >
    <template #body>
      <div v-html="disclaimerText" />
    </template>
  </modal>
</template>

<script>
import $modals from '@shared/modal/index'
import Modal from '@shared/components/Modal.vue'

export default {
  name: 'DisclaimerModal',
  components: { Modal },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    disclaimerText: {
      type: String,
      default: '',
    },
  },
  unmounted() {
    $modals.close()
  },
  methods: {
    closeModal(e) {
      e.preventDefault()
      $modals.close()
      this.onClose()
    },
  },
}
</script>

<style lang="scss">
.modal-container {
  &.disclaimer-modal {
    position: relative;
    max-width: 800px;
    width: 94%;
    margin: 0 auto;
    padding: 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    .modal-header {
      &-close-btn {
        background-color: transparent;
      }
    }
    .modal-body {
      height: 80%;
      margin: 0 auto 30px auto;
      horiz-align: center;
      max-width: 600px;
      img {
        width: 100%;
      }
      h2 {
        text-align: center;
        font-weight: 700;
      }
      p {
        text-align: center;
      }
    }
    .footer-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  .modal-container {
    &.disclaimer-modal {
      width: 94%;
    }
  }
}
</style>
