<template>
  <button
    v-if="isActive"
    :class="getCSSClasses"
    :type="type"
    role="button"
    :aria-label="text"
    :data-qa="dataQA"
    @click="handleClickEvent"
    @touchstart="handleTouchStartEvent"
    @submit="handleSubmitEvent"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="text"
      class="interactive-button__text"
      v-html="text"
    />
  </button>
  <div
    v-else
    :class="getCSSClasses"
    :data-qa="dataQA"
  >
    <span class="interactive-button__middle" />
    <LottieAnimationContainer
      v-if="isProcessing"
      class="interactive-button__loader"
      :json-config-path="jsonConfigPath"
    />
    <div
      v-if="isActive || isDisabled"
      class="interactive-button__text"
      v-html="text"
    />
    <!--eslint-enable-->
  </div>
</template>

<script>
import LottieAnimationContainer from '@shared/components/LottieAnimationContainer.vue'

// TODO: refactor;
const STATES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  PROCESSING: 'PROCESSING',
  VALIDATED: 'VALIDATED',
}

export default {
  name: 'InteractiveButton',
  components: {
    LottieAnimationContainer,
  },
  inheritAttrs: false,
  props: {
    cssClasses: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'submit',
    },
    text: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: STATES.ACTIVE,
    },
    dataQA: {
      type: String,
      default: '',
    },
  },
  emits: ['click', 'submit'],
  computed: {
    jsonConfigPath() {
      return `${
        import.meta.env.VITE_PUBLIC_PATH
      }static/lottie-processing-button-spinner.json`
    },
    getCSSClasses() {
      let stateClass = ''

      if (this.state === STATES.ACTIVE) {
        stateClass = 'interactive-button--active js-interactive-button--active'
      } else if (this.state === STATES.DISABLED) {
        stateClass =
          'interactive-button--disabled js-interactive-button--disabled'
      } else if (this.state === STATES.PROCESSING) {
        stateClass =
          'interactive-button--processing js-interactive-button--processing'
      } else if (this.state === STATES.VALIDATED) {
        stateClass =
          'interactive-button--validated js-interactive-button--validated'
      }

      return ['interactive-button', stateClass, ...this.cssClasses]
    },
    isActive() {
      return this.state === STATES.ACTIVE
    },
    isProcessing() {
      return this.state === STATES.PROCESSING
    },
    isDisabled() {
      return this.state === STATES.DISABLED
    },
  },
  methods: {
    handleClickEvent(event) {
      if (this.state === STATES.ACTIVE) {
        this.$emit('click', event)
      }
    },
    handleSubmitEvent(event) {
      if (this.state === STATES.ACTIVE) {
        this.$emit('submit', event)
      }
    },
    // to make :active preudo-class active for iOS devices;
    handleTouchStartEvent() {},
  },
}
</script>

<style lang="scss">
@import './css/InteractiveButton.scss';
</style>
