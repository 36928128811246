<template>
  <div class="virginpulse-sso-content">
    <Spinner v-html="$t('b2b__SSO__redirecting_to_virginpulse')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Spinner from '@shared/components/Spinner.vue'

export default {
  name: 'B2BVirginpulseSSO',
  components: {
    Spinner,
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('b2b', ['corporate']),
  },
  created() {
    if (this.corporate.key === 'mocked-idp') {
      this.connectWithMockedIDP()
      return
    }

    this.connectWithVirginpulse()
  },
  methods: {
    connectWithVirginpulse() {
      window.location.href = `${
        import.meta.env.VITE_API_BASE_URL
        // somehow the VP entrypoint is not wrapped in the API under /v2/
      }/auth/saml?resource_class=User`
    },
    connectWithMockedIDP() {
      if (window.location.hostname === 'localhost') {
        window.location.href = `${
          import.meta.env.VITE_API_BASE_URL
        }/v2/dev_auth/saml?resource_class=User`
      } else {
        window.location.href = `${
          import.meta.env.VITE_API_BASE_URL
        }/v2/fake_auth/saml?resource_class=User&redirect_host=${
          window.location.hostname
        }`
      }
    },
  },
}
</script>

<style lang="scss">
@import './css/B2BVirginpulseSSOInitiated';
</style>
