import { PublicApi, PrivateApi } from '@shared/api-client'

export * from './b2b'

export const login = (email, password, stayLoggedIn) => {
  const payload = { email, password }
  if (typeof stayLoggedIn === 'boolean') {
    payload.stay_logged_in = stayLoggedIn
  }

  return PublicApi.post('auth/sign_in/', payload)
}

export const signUp = ({
  email,
  password,
  firstName,
  lastName,
  insuranceType,
  phoneNumber,
  usState,
  dateOfBirth,
  spouse,
  language,
  country,
  timezone,
  acceptedDataProtection,
  acceptedTracking,
  stayLoggedIn,
  corporateId,
  corporateEligibilityData,
  fromApp, // from session storage
  verificationEmail,
  eligibilitySessionHash,
  lookupKey,
  landingPageExperimentVariant,
  signupContext,
}) =>
  PublicApi.post('auth/sign_up/', {
    email,
    password,
    first_name: firstName,
    ...(lastName && { last_name: lastName }),
    ...(phoneNumber && { phone_number: phoneNumber }),
    ...(insuranceType && {
      insurance_type:
        insuranceType === 'private' ? 'private_insurance' : 'public_insurance',
    }),
    corporate_id: corporateId,
    language,
    country,
    timezone,
    accepted_data_protection: acceptedDataProtection,
    accepted_tracking: acceptedTracking,
    stay_logged_in: stayLoggedIn,
    ...(eligibilitySessionHash && {
      eligibility_session_hash: eligibilitySessionHash,
    }),
    ...(lookupKey && { solera_lookup_key: lookupKey }),
    signup_context: {
      web_entry_point_url: signupContext.webEntryPointUrl,
      web_entry_point_referrer_url: signupContext.webEntryPointReferrerUrl,
      how_did_you_hear_about_us: signupContext.howDidYouHearAboutUs,
    },
    feedback: [
      ...Object.entries({
        us_state: usState,
        date_of_birth: dateOfBirth,
        spouse,
        from_app: fromApp,
        email: verificationEmail,
        landing_page_experiment_variant: landingPageExperimentVariant,
        ...corporateEligibilityData,
      })
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((entry) => entry.value != null),
    ],
  })

export const resetPassword = ({ email }) =>
  PublicApi.post('auth/password/reset_init', {
    email,
  })

export const loginWithOneTimeAuthToken = (token) =>
  PublicApi.post('auth/sign_in/token', {
    token,
  })

export const getOneTimeAuthToken = () => PrivateApi.get('auth/token')

export const subscribeToDvg = ({ code, corporateId }) =>
  PrivateApi.post('subscriptions/subscribe_to_dvg', {
    code,
    corporate_id: corporateId,
  })

export const sendVerificationData = (data) =>
  PrivateApi.post('user/add_feedback/', {
    feedback: Object.keys(data).map((k) => ({
      key: k === 'participationId' ? 'participation_id' : k,
      value: data[k],
    })),
  })

export const triggerCorporateSubscriptionCreationOrProlongation = ({
  corporateKey,
  eligibilityParams = {},
}) => {
  if (eligibilityParams.participationId != null) {
    eligibilityParams.participation_id = eligibilityParams.participationId
    delete eligibilityParams.participationId
  }
  return PrivateApi.post('user/partner_subscriptions/', {
    corporate_key: corporateKey,
    eligibility_check: eligibilityParams,
  })
}

export const uploadPrescriptionFile = (formData, params) => {
  params = Object(params) === params ? params : {}

  return PrivateApi.post('user/prescriptions', formData, params)
}

export const getPopularCorporates = () => PublicApi.get('popular_corporates')

export const sendHcpInformation = ({ name, city, zip }) =>
  PrivateApi.post('user/hcp_information', {
    name,
    city,
    ...(typeof zip === 'string' && zip.length > 0 ? { zip } : {}),
  })

export const sendSubscriptionActivationEntry = ({ type, attributes }) =>
  PrivateApi.post('subscription_activation_entries', {
    data: {
      type,
      attributes,
    },
  })
