import AbstractModel from './utils/abstractModel'
import CurrentSubscription from './currentSubscription'
import UserProfile from './userProfile'
import UserState from './userState'
import types from './utils/types'

class User extends AbstractModel {
  /**
   * @type {integer}
   */
  id

  /**
   * @type {Date} // 2023-07-19T16:22:21.074Z
   */
  createdAt

  /**
   * @type {CurrentSubscription, undefined}
   */
  currentSubscription

  /**
   * @type {string}
   */
  email

  /**
   * @type {string}
   */
  guid

  /**
   * @type {array}
   */
  metrics

  /*
   * @type {string}
   */
  painLocation

  /**
   * @type {UserProfile}
   */
  profile

  /**
   * @type {UserState}
   */
  state

  /**
   * @type {boolean}
   */
  stayLoggedIn

  /**
   * @type {array}
   */
  subscriptions

  /**
   * @type {string, undefined}
   */
  onboardingKitSelection // this one is not set via API

  constructor(json) {
    super(json)
    this.id = json.id
    this.createdAt = json.created_at && new Date(json.created_at)
    this.currentSubscription =
      json.current_subscription &&
      new CurrentSubscription(json.current_subscription)
    this.email = json.email
    this.guid = json.guid
    this.metrics = json.metrics
    this.painLocation = json.pain_location
    this.profile = json.profile && new UserProfile(json.profile)
    this.state = json.state && new UserState(json.state)
    this.stayLoggedIn = json.stay_logged_in
    this.subscriptions = json.subscriptions

    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('createdAt', [types.DATE])
    this.assertType('currentSubscription', [CurrentSubscription, types.NULL])
    this.assertType('email', [types.STRING])
    this.assertType('guid', [types.STRING])
    this.assertType('metrics', [types.ARRAY, types.NULL])
    this.assertType('painLocation', [types.STRING, types.NULL])
    this.assertType('profile', [UserProfile])
    this.assertType('state', [UserState])
    this.assertType('stayLoggedIn', [types.BOOLEAN])
    this.assertType('subscriptions', [types.ARRAY, types.NULL])
  }

  /**
   * Corporate key of the current subscription
   *
   * @readonly
   * @type {string, undefined}
   */
  get currentSubscriptionCorporateKey() {
    return this.currentSubscription?.corporateKey
  }

  /**
   * Indicate whether user is Pro
   * legacy, we should use current_subscription when possible
   *
   * @readonly
   * @type {boolean}
   */
  get isPro() {
    return this.state.isPro === true
  }

  /**
   * Identifies if currentSubscription is expired.
   * Also will return `true` in case there is no currentSubscription
   *
   * @readonly
   * @type {boolean}
   */
  get currentSubscriptionIsExpired() {
    return this.currentSubscription?.isExpired ?? true
  }
}

export default User
