<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="three-steps">
    <div class="three-steps__container">
      <div class="three-steps__container__cta">
        <h1
          class="three-steps__container__cta--title"
          v-html="$t('b2b__landing_page__landing_3_steps__title')"
        />
        <div class="three-steps__container__cta--button">
          <BaseButton @click="handleCTAButtonClick"
            >{{ ctaBtnTitle }}

            <template #append> <ArrowRightIcon /> </template>
          </BaseButton>
        </div>
      </div>
      <div class="three-steps__container__steps">
        <div
          v-for="({ icon, title }, index) in cards"
          :key="index"
          class="three-steps__container__steps--step"
        >
          <AssetImage
            :name="icon"
            class="three-steps__container__steps--step--icon"
          />
          <p
            class="three-steps__container__steps--step--title"
            v-html="$t(title)"
          />
        </div>
      </div>

      <div class="three-steps__container__mobile-cta">
        <BaseButton @click="handleCTAButtonClick"
          >{{ ctaBtnTitle }}
          <template #append> <ArrowRightIcon /> </template>
        </BaseButton>
      </div>
    </div>
  </div>
  <!--eslint-enable-->
</template>

<script setup>
import { computed } from 'vue'
import BaseButton from '@shared/components/BaseButton.vue'
import AssetImage from '@shared/components/AssetImage.vue'
import ArrowRightIcon from '@shared/assets/icon/ArrowRightIcon.vue'

defineProps({
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  ctaBtnTitle: {
    type: String,
    default: '',
  },
})

const cards = computed(() => [
  {
    icon: 'account_setup.svg',
    title: 'b2b__landing_page__landing_3_steps__card_one__title',
  },
  {
    icon: 'download.svg',
    title: 'b2b__landing_page__landing_3_steps__card_two__title',
  },
  {
    icon: 'first_day.svg',
    title: 'b2b__landing_page__landing_3_steps__card_three__title',
  },
])
</script>

<style lang="scss" scoped>
.three-steps {
  padding: 40px 10px;
  background: $blue-subtle;

  @include media-up(lg) {
    padding: 96px 10px;
  }

  &__container {
    @extend %container;
    margin: 0 auto;
    @include flex-container(column, flex-start, md);

    @include media-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__cta {
      &--title {
        text-align: center;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;

        @include media-up(md) {
          text-align: left;
          max-width: 400px;
        }

        font-weight: normal;
      }

      &--button {
        display: none;

        @include media-up(md) {
          display: inline-block;
        }
      }
    }

    &__steps {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 0 50px;
      grid-gap: 70px;
      max-width: 450px;

      @include media-up(md) {
        padding: 0;
        margin: 0;
      }

      %line {
        position: absolute;
        content: '';
        width: 2px;
        height: 70px;
        background: $sky;
        left: 30px;
        bottom: 100%;
      }

      &--step {
        display: flex;
        grid-gap: 30px;
        align-items: start;
        position: relative;

        &:nth-child(2):before,
        &:nth-child(3):before {
          @extend %line;
        }

        &--title {
          margin: 2px 0 0 0;
        }

        &--icon {
          height: 60px;
          width: 60px;
        }
      }
    }

    &__mobile-cta {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      @include media-up(md) {
        display: none;
      }
    }
  }
}
</style>
