<template>
  <div class="corp-header">
    <div
      v-if="corporate?.logoFile"
      class="corp-logo"
    >
      <img
        :src="corporate.logoFile"
        alt="logo"
      />
    </div>
    <div
      v-else-if="corporate?.title"
      class="corp-logo-text"
    >
      {{ corporate.title }}
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapB2BGetters } = createNamespacedHelpers('b2b')

export default {
  name: 'CorporateHeader',
  computed: {
    ...mapB2BGetters(['corporate']),
  },
}
</script>

<style lang="scss" scoped>
.corp-header {
  .corp-logo {
    margin: 30px 0;

    img {
      max-height: 80px;
      max-width: 320px;

      @media (max-width: $breakpoint-mobile) {
        max-height: 80px;
        max-width: 272px;
      }
    }
  }

  .corp-logo-text {
    font-weight: $font-weight-bold;
    font-size: 40px;
    margin: 28px 0;
  }
}
</style>
