import AbstractModel from './utils/abstractModel'
import types from './utils/types'

class CurrentSubscription extends AbstractModel {
  /**
   * @type {integer}
   */
  id

  /**
   * @type {string}
   */
  status

  /**
   * @type {string}
   */
  corporateKey

  /**
   * @type {integer, undefined}
   */
  corporateId

  /**
   * @type {JSON}
   */
  options

  /**
   * @type {date | null}
   */
  expiresAt

  static STATUSES = {
    ACTIVE: 'active',
    EXPIRED: 'expired',
    EXPIRING: 'expiring',
    UNKNOWN: 'unknown',
  }

  constructor(json) {
    super(json)
    this.id = json.id
    this.status = json.status
    this.corporateKey = json.corporate_key
    this.options = json.options
    this.corporateId = json.corporate_id
    this.expiresAt = this.parseDate(json.expires_at)

    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('status', [types.STRING])
    this.assertType('corporateKey', [types.STRING])
    this.assertType('options', [types.JSON, types.NULL])
    this.assertType('corporateId', [types.NUMBER])
    this.assertType('expiresAt', [types.DATE, types.NULL])
  }

  get isExpired() {
    return this.status === this.constructor.STATUSES.EXPIRED
  }
}

export default CurrentSubscription
