<template>
  <div :class="['message-box', classes]">
    <h6 v-if="title">{{ $t(title) }}</h6>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    type: {
      type: String,
      default: 'warn',
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      return {
        ...{ 'message-box-success': this.type === 'success' },
        ...{ 'message-box-warn': this.type === 'warn' },
        ...{ 'message-box-error': this.type === 'error' },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.message-box {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  h6 {
    margin: 0 0 2px 0;
  }
  p {
    margin: 0 0 2px !important;
    font-size: 17px !important;
    line-height: 24px !important;
  }
  .btn {
    margin-top: 16px;
  }
  &-warn {
    background: $warning;
  }
  &-error {
    background: #f1bed2;
  }
  &-success {
    background: $mint;
  }
}
@media (max-width: $breakpoint-mobile) {
  .message-box {
    padding: 16px;
    p {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
}
</style>
