import Tracker from '@shared/Tracker'
import env from '@shared/env.js'

const langMapper = {
  de: 'de-DE',
  en: 'en-US',
  es: 'es-US',
  'de-de': 'de-DE',
  'en-us': 'en-US',
  'es-us': 'es-US',
}

export const wait = (seconds) =>
  new Promise((resolve) => setTimeout(resolve, seconds))

export const normalizeLang = (lang) => {
  lang = String(lang)
  return langMapper[lang.toLowerCase()] || lang
}
export const getLangCodeByLanguage = (lang) => lang.split('-')[0]
export const getSupportedLangCodes = () =>
  env.VITE_SUPPORTED_LANG_CODES.split(',')

// WARN: Eslint rule: no-useless-escape
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)',
    ),
  )
  return matches ? decodeURIComponent(matches[1]) : null
}

export function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    ...options,
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  for (let optionKey in options) {
    updatedCookie += `; ${optionKey}`
    const optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  }
  document.cookie = updatedCookie
}

// dont use this directly, user state.getters.lang instead
export function getNavigatorLanguage() {
  const lang =
    (window.navigator.languages || []).find(
      (k) => k.length === 5 || k.length === 2,
    ) ||
    window.navigator.language ||
    window.navigator.userLanguage
  return normalizeLang(lang)
}

export const getDefaultLanguage = () => {
  const searchParams = new URLSearchParams(window.location.search)
  let searchParamLanguage = searchParams.get('language') || ''
  searchParamLanguage = langMapper[searchParamLanguage.toLowerCase()]
  const supportedLangCodes = getSupportedLangCodes()
  const navigatorLanguage = getNavigatorLanguage()
  const defaultLangCode = getLangCodeByLanguage(
    searchParamLanguage ?? navigatorLanguage,
  )

  return supportedLangCodes.includes(defaultLangCode)
    ? normalizeLang(defaultLangCode)
    : normalizeLang(supportedLangCodes[0])
}

export const arrayToObject = (fields = []) => {
  if (!Array.isArray(fields)) {
    throw new Error(
      `'fields' expected to be an array, ${
        fields === null ? 'null' : typeof fields
      } got instead`,
    )
  }
  return fields.reduce((prev, path) => {
    if (typeof path !== 'string') {
      path = String(path)
    }

    const key = path.split('.').slice(-1)[0]

    if (prev[key]) {
      throw new Error(`The key \`${key}\` is already in use.`)
    }

    prev[key] = path

    return prev
  }, {})
}

// Navigator.maxTouchPoints-based mobile detection taken from
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Device_Detection
export const isMobile = () => {
  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0
  }
  if ('msMaxTouchPoints' in navigator) {
    return navigator.msMaxTouchPoints > 0
  }
  const mQ = window.matchMedia && matchMedia('(pointer:coarse)')
  if (mQ && mQ.media === '(pointer:coarse)') {
    return !!mQ.matches
  }
  if ('orientation' in window) {
    return true // deprecated, but good fallback
  }
  // Only as a last resort, fall back to user agent sniffing
  const UA = navigator.userAgent
  return (
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
  )
}

// We need to exclude MSStream because MS includes `iPhone` into its' browser to trick GMail;
// To distinguish between iOS and Android devices we search for Android pattern in User Agent;
// Then a combination with isMobile detection should do the trick;
// Unfortunately we can not rely on a simple iPhone/iPad/iPod check since
// modern iPads omit iPad within their User Agent strings;
export const isAndroidAgent = () => /Android/.test(window.navigator.userAgent)

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.substring(1)

export const getTimezone = () =>
  window.Intl && typeof window.Intl === 'object'
    ? window.Intl.DateTimeFormat().resolvedOptions().timeZone
    : env.VITE_DEFAULT_TIMEZONE

export const getAge = (dateString) => {
  const baseDate = new Date(0)
  const diff = new Date(Math.abs(new Date() - new Date(dateString)))

  return diff.getFullYear() - baseDate.getFullYear()
}

export const coerceAtLeast = (val, min) =>
  val === null || val === undefined || val < min ? min : val

// To be used in `JSON.stringify` to replace all values that are not safe to be logged
const logReplacer = (key, value) => {
  // if the key matches any of the bad keys the value us replaced with stars
  const badKeys = ['password']
  const subst = '******'
  if (badKeys.includes(key)) {
    return subst
  }

  // if the value is a stringified JSON all values of badKeys that can be found in this JSON are
  // replaced with stars
  const badKeysRegex = badKeys.map((badKey) => `"${badKey}":"`).join('|')
  // Safari (13.1.x) doesn't support lookbehind yet (?<=);
  const regex = new RegExp(`(?:${badKeysRegex})(?:\\\\"|[^"])*`, 'gim')
  if (typeof value === 'string' || value instanceof String) {
    return value.replace(regex, `${badKeysRegex}${subst}`)
  }

  // otherwise the value is not mutated
  return value
}

/**
 * If the input object is a string this function returns that string
 * and otherwise the json representation of the input object as a string.
 * @param object {*}
 * @returns {string}
 */
const prepareForLog = (object) =>
  typeof object === 'string' ? object : JSON.stringify(object, logReplacer)

/**
 * @param [log_level="info"] {"info"|"warn"|"error"}
 * @param title {string}
 * @param message {string}
 * @param [backstack=""] {string}
 * @param [tags=null] {Array.<string>}
 */
const log = (log_level, title, message, backstack = '', tags = null) => {
  console.log(`${log_level}: ${title}: ${prepareForLog(message)}`, backstack)
  Tracker.trackLog(log_level, title, prepareForLog(message), backstack, tags)
}

export const logI = (title, message, tags = null) =>
  log('info', title, message, '', tags)

export const logW = (title, err) => log('warning', title, err)

export const logE = (err) =>
  log('error', err.message, `${err.line} ${err.column}`, err)

export const SIZE_MEDIUM = 768

export const isNumeric = (k) => /^[0-9]+$/.test(k)

const versionChunkCompare = (l, r) => {
  const lnumeric = isNumeric(l)
  const rnumeric = isNumeric(r)

  return l === r
    ? 0
    : lnumeric && !rnumeric
      ? -1
      : !lnumeric && rnumeric
        ? 1
        : Number(l) < Number(r)
          ? -1
          : 1
}

// Compares two semantic version strings.
export const semVerCompare = (lver, rver) => {
  const lSemVer = String(lver).trim().split('.')
  const rSemVer = String(rver).trim().split('.')

  return lSemVer.length < 3
    ? -1
    : rSemVer.length < 3
      ? 1
      : versionChunkCompare(lSemVer[0], rSemVer[0]) ||
        versionChunkCompare(lSemVer[1], rSemVer[1]) ||
        versionChunkCompare(lSemVer[2], rSemVer[2])
}

// test whether a key contains all of the substrings provided in the arguments.
// case is ignored. no other sanity checks.
const keySearchGeneric = (key, ...args) =>
  typeof key === 'string' &&
  key.length > 0 &&
  args.every((term) => key.toLowerCase().includes(term.toLowerCase()))

// these functions are used to select prefill data from fields where the key is not known.
// not to be used for other purposes.
export const isEmailKey = (key) => keySearchGeneric(key, 'email')
export const isFirstNameKey = (key) => /first[^a-zA-Z]?(?:name)?$/i.test(key)
export const isLastNameKey = (key) => /last[^a-zA-Z]?(?:name)?$/i.test(key)
export const isDoBKey = (key) =>
  keySearchGeneric(key, 'dob') || keySearchGeneric(key, 'date', 'of', 'birth')

export const countryList = [
  ['Afghanistan (‫افغانستان‬‎)', 'af', '93'],
  ['Albania (Shqipëri)', 'al', '355'],
  ['Algeria (‫الجزائر‬‎)', 'dz', '213'],
  ['American Samoa', 'as', '1684'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Anguilla', 'ai', '1264'],
  ['Antigua and Barbuda', 'ag', '1 268'],
  ['Argentina', 'ar', '54'],
  ['Armenia (Հայաստան)', 'am', '374'],
  ['Aruba', 'aw', '297'],
  ['Australia', 'au', '61', 0],
  ['Austria (Österreich)', 'at', '43'],
  ['Azerbaijan (Azərbaycan)', 'az', '994'],
  ['Bahamas', 'bs', '1 242'],
  ['Bahrain (‫البحرين‬‎)', 'bh', '973'],
  ['Bangladesh (বাংলাদেশ)', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Belarus (Беларусь)', 'by', '375'],
  ['Belgium (België)', 'be', '32'],
  ['Belize', 'bz', '501'],
  ['Benin (Bénin)', 'bj', '229'],
  ['Bermuda', 'bm', '1441'],
  ['Bhutan (འབྲུག)', 'bt', '975'],
  ['Bolivia', 'bo', '591'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brazil (Brasil)', 'br', '55'],
  ['British Indian Ocean Territory', 'io', '246'],
  ['British Virgin Islands', 'vg', '1284'],
  ['Brunei', 'bn', '673'],
  ['Bulgaria (България)', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi (Uburundi)', 'bi', '257'],
  ['Cambodia (កម្ពុជា)', 'kh', '855'],
  ['Cameroon (Cameroun)', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cape Verde (Kabu Verdi)', 'cv', '238'],
  ['Caribbean Netherlands', 'bq', '599', 1],
  ['Cayman Islands', 'ky', '1 345'],
  ['Central African Republic (République centrafricaine)', 'cf', '236'],
  ['Chad (Tchad)', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China (中国)', 'cn', '86'],
  ['Christmas Island', 'cx', '61', 2],
  ['Cocos (Keeling) Islands', 'cc', '61', 1],
  ['Colombia', 'co', '57'],
  ['Comoros (‫جزر القمر‬‎)', 'km', '269'],
  ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243'],
  ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242'],
  ['Cook Islands', 'ck', '682'],
  ['Costa Rica', 'cr', '506'],
  ['Côte d’Ivoire', 'ci', '225'],
  ['Croatia (Hrvatska)', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', 0],
  ['Cyprus (Κύπρος)', 'cy', '357'],
  ['Czech Republic (Česká republika)', 'cz', '420'],
  ['Denmark (Danmark)', 'dk', '45'],
  ['Djibouti', 'dj', '253'],
  ['Dominica', 'dm', '1 767'],
  [
    'Dominican Republic (República Dominicana)',
    'do',
    '1',
    2,
    ['809', '829', '849'],
  ],
  ['Ecuador', 'ec', '593'],
  ['Egypt (‫مصر‬‎)', 'eg', '20'],
  ['El Salvador', 'sv', '503'],
  ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
  ['Eritrea', 'er', '291'],
  ['Estonia (Eesti)', 'ee', '372'],
  ['Ethiopia', 'et', '251'],
  ['Falkland Islands (Islas Malvinas)', 'fk', '500'],
  ['Faroe Islands (Føroyar)', 'fo', '298'],
  ['Fiji', 'fj', '679'],
  ['Finland (Suomi)', 'fi', '358', 0],
  ['France', 'fr', '33'],
  ['French Guiana (Guyane française)', 'gf', '594'],
  ['French Polynesia (Polynésie française)', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgia (საქართველო)', 'ge', '995'],
  ['Germany (Deutschland)', 'de', '49'],
  ['Ghana (Gaana)', 'gh', '233'],
  ['Gibraltar', 'gi', '350'],
  ['Greece (Ελλάδα)', 'gr', '30'],
  ['Greenland (Kalaallit Nunaat)', 'gl', '299'],
  ['Grenada', 'gd', '1 473'],
  ['Guadeloupe', 'gp', '590', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502'],
  ['Guernsey', 'gg', '44', 1],
  ['Guinea (Guinée)', 'gn', '224'],
  ['Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haiti', 'ht', '509'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong (香港)', 'hk', '852'],
  ['Hungary (Magyarország)', 'hu', '36'],
  ['Iceland (Ísland)', 'is', '354'],
  ['India (भारत)', 'in', '91'],
  ['Indonesia', 'id', '62'],
  ['Iran (‫ایران‬‎)', 'ir', '98'],
  ['Iraq (‫العراق‬‎)', 'iq', '964'],
  ['Ireland', 'ie', '353'],
  ['Isle of Man', 'im', '44', 2],
  ['Israel (‫ישראל‬‎)', 'il', '972'],
  ['Italy (Italia)', 'it', '39', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japan (日本)', 'jp', '81'],
  ['Jersey', 'je', '44', 3],
  ['Jordan (‫الأردن‬‎)', 'jo', '962'],
  ['Kazakhstan (Казахстан)', 'kz', '7', 1],
  ['Kenya', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait (‫الكويت‬‎)', 'kw', '965'],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
  ['Laos (ລາວ)', 'la', '856'],
  ['Latvia (Latvija)', 'lv', '371'],
  ['Lebanon (‫لبنان‬‎)', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Liberia', 'lr', '231'],
  ['Libya (‫ليبيا‬‎)', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lithuania (Lietuva)', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macau (澳門)', 'mo', '853'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
  ['Madagascar (Madagasikara)', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malaysia', 'my', '60'],
  ['Maldives', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marshall Islands', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritania (‫موريتانيا‬‎)', 'mr', '222'],
  ['Mauritius (Moris)', 'mu', '230'],
  ['Mayotte', 'yt', '262', 1],
  ['Mexico (México)', 'mx', '52'],
  ['Micronesia', 'fm', '691'],
  ['Moldova (Republica Moldova)', 'md', '373'],
  ['Monaco', 'mc', '377'],
  ['Mongolia (Монгол)', 'mn', '976'],
  ['Montenegro (Crna Gora)', 'me', '382'],
  ['Montserrat', 'ms', '1664'],
  ['Morocco (‫المغرب‬‎)', 'ma', '212', 0],
  ['Mozambique (Moçambique)', 'mz', '258'],
  ['Myanmar (Burma) (မြန်မာ)', 'mm', '95'],
  ['Namibia (Namibië)', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal (नेपाल)', 'np', '977'],
  ['Netherlands (Nederland)', 'nl', '31'],
  ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
  ['New Zealand', 'nz', '64'],
  ['Nicaragua', 'ni', '505'],
  ['Niger (Nijar)', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Niue', 'nu', '683'],
  ['Norfolk Island', 'nf', '672'],
  ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850'],
  ['Northern Mariana Islands', 'mp', '1 670'],
  ['Norway (Norge)', 'no', '47', 0],
  ['Oman (‫عُمان‬‎)', 'om', '968'],
  ['Pakistan (‫پاکستان‬‎)', 'pk', '92'],
  ['Palau', 'pw', '680'],
  ['Palestine (‫فلسطين‬‎)', 'ps', '970'],
  ['Panama (Panamá)', 'pa', '507'],
  ['Papua New Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Peru (Perú)', 'pe', '51'],
  ['Philippines', 'ph', '63'],
  ['Poland (Polska)', 'pl', '48'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
  ['Qatar (‫قطر‬‎)', 'qa', '974'],
  ['Réunion (La Réunion)', 're', '262', 0],
  ['Romania (România)', 'ro', '40'],
  ['Russia (Россия)', 'ru', '7', 0],
  ['Rwanda', 'rw', '250'],
  ['Saint Barthélemy', 'bl', '590', 1],
  ['Saint Helena', 'sh', '290'],
  ['Saint Kitts and Nevis', 'kn', '1 869'],
  ['Saint Lucia', 'lc', '1758'],
  ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
  ['Saint Vincent and the Grenadines', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
  ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966'],
  ['Senegal (Sénégal)', 'sn', '221'],
  ['Serbia (Србија)', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapore', 'sg', '65'],
  ['Sint Maarten', 'sx', '1 721'],
  ['Slovakia (Slovensko)', 'sk', '421'],
  ['Slovenia (Slovenija)', 'si', '386'],
  ['Solomon Islands', 'sb', '677'],
  ['Somalia (Soomaaliya)', 'so', '252'],
  ['South Africa', 'za', '27'],
  ['South Korea (대한민국)', 'kr', '82'],
  ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211'],
  ['Spain (España)', 'es', '34'],
  ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
  ['Sudan (‫السودان‬‎)', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Svalbard and Jan Mayen', 'sj', '47', 1],
  ['Swaziland', 'sz', '268'],
  ['Sweden (Sverige)', 'se', '46'],
  ['Switzerland (Schweiz)', 'ch', '41'],
  ['Syria (‫سوريا‬‎)', 'sy', '963'],
  ['Taiwan (台灣)', 'tw', '886'],
  ['Tajikistan', 'tj', '992'],
  ['Tanzania', 'tz', '255'],
  ['Thailand (ไทย)', 'th', '66'],
  ['Timor-Leste', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tokelau', 'tk', '690'],
  ['Tonga', 'to', '676'],
  ['Trinidad and Tobago', 'tt', '1 868'],
  ['Tunisia (‫تونس‬‎)', 'tn', '216'],
  ['Turkey (Türkiye)', 'tr', '90'],
  ['Turkmenistan', 'tm', '993'],
  ['Turks and Caicos Islands', 'tc', '1 649'],
  ['Tuvalu', 'tv', '688'],
  ['U.S. Virgin Islands', 'vi', '1 340'],
  ['Uganda', 'ug', '256'],
  ['Ukraine (Україна)', 'ua', '380'],
  ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971'],
  ['United Kingdom', 'gb', '44', 0],
  ['United States', 'us', '1', 0],
  ['Uruguay', 'uy', '598'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998'],
  ['Vanuatu', 'vu', '678'],
  ['Vatican City (Città del Vaticano)', 'va', '39', 1],
  ['Venezuela', 've', '58'],
  ['Vietnam (Việt Nam)', 'vn', '84'],
  ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681'],
  ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', 1],
  ['Yemen (‫اليمن‬‎)', 'ye', '967'],
  ['Zambia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
  ['Åland Islands', 'ax', '358', 1],
]

export const countryDetails = {
  ca: {
    name: {
      simple: 'Canada',
      display: 'Canada',
    },
    iso: 'CA',
    phone: '1',
  },
  de: {
    name: {
      simple: 'Germany',
      display: 'Germany (Deutschland)',
    },
    iso: 'DE',
    phone: '49',
  },
  hk: {
    name: {
      simple: 'Hong Kong',
      display: 'Hong Kong (香港)',
    },
    iso: 'HK',
    phone: '852',
  },
  nl: {
    name: {
      simple: 'Netherlands',
      display: 'Netherlands (Nederland)',
    },
    iso: 'NL',
    phone: '31',
  },
  sg: {
    name: {
      simple: 'Singapore',
      display: 'Singapore',
    },
    iso: 'SG',
    phone: '65',
  },
  ch: {
    name: {
      simple: 'Switzerland',
      display: 'Switzerland (Schweiz)',
    },
    iso: 'CH',
    phone: '41',
  },
  th: {
    name: {
      simple: 'Thailand',
      display: 'Thailand (ไทย)',
    },
    iso: 'TH',
    phone: '66',
  },
  ae: {
    name: {
      simple: 'United Arab Emirates',
      display: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    },
    iso: 'AE',
    phone: '971',
  },
  gb: {
    name: {
      simple: 'United Kingdom',
      display: 'United Kingdom',
    },
    iso: 'GB',
    phone: '44',
  },
  us: {
    name: {
      simple: 'United States',
      display: 'United States',
    },
    iso: 'US',
    phone: '1',
  },
  other: {
    name: {
      simple: 'Other',
      display: 'Other (Country not listed)',
    },
    iso: 'OTHER',
    phone: '0',
  },
}

export const converter = {
  ftToCm: (ft) => converter.inToCm(converter.ftToIn(ft)),
  ftToIn: (ft) => ft * 12,
  inToCm: (inch) => inch * 2.54,
  cmToFt: (cm) => cm / (2.54 * 12),
  kgToLb: (kg) => kg * 2.205,
  lbToKg: (lb) => lb / 2.205,
}

export const hasMinLength = (password) => password.length >= 8

export const hasLowerCaseChars = (password) => /(?=.*[a-z])/.test(password)

export const hasUpperCaseChars = (password) => /(?=.*[A-Z])/.test(password)

export const hasDigitOrSymbol = (password) => /(?=.*[^a-zA-Z])/.test(password)

export const ESC = 27

export const isObject = (obj) =>
  Object.prototype.toString(obj) === '[object Object]'

export const escapeHtmlChars = (string) => {
  if (typeof string !== 'string') {
    return ''
  }

  return string.replace(/[<>]/g, (match) => (match === '<' ? '&lt;' : '&gt;'))
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const chunk = (array, chunkSize) =>
  array.reduce((all, one, i) => {
    const ch = Math.floor(i / chunkSize)
    all[ch] = [].concat(all[ch] || [], one)
    return all
  }, [])

/**
 * URI decode the value of every query string component
 * @param {Object.<string, string>} query containing encoded values
 * @returns {Object.<string, string>} query containing decoded values
 */
export const decodeQuery = (query) =>
  Object.keys(query).reduce((result, key) => {
    result[key] = decodeURIComponent(query[key])
    return result
  }, {})

export const validateDate = (year, month, day) => {
  const d = new Date(year, month, day)
  if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
    return true
  }
  return false
}

/**
 * generates a signupContexts object filled with all information that is known about the user at start time
 * of the webcheckout. This is to be used on all product variants.
 * - the referrer url will be empty if the document.referrer is the same as the current url
 * - the protocol (`http://` or `https://`) are not part of the payload so that they are not blocked by WAF
 * @returns {{webEntryPointUrl: string, webEntryPointReferrerUrl: (string)}} the signupContext
 */
export const generateSignupContext = () => {
  const referrerDifferentFromHref =
    document.referrer === window.location.href ? '' : document.referrer
  return {
    webEntryPointUrl: window.location.href.split('://').pop(),
    webEntryPointReferrerUrl: referrerDifferentFromHref.split('://').pop(),
  }
}
