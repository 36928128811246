<template>
  <text-input
    input-name="email"
    input-type="email"
    :input-label="inputLabel || $t('generic_field_email')"
    :default-value="defaultValue"
    :state="stateComputed"
    :autofocus="autofocus"
    :autocomplete="false"
    :disabled="disabled"
    :error-message="errorMessageComputed"
    :error-code="errorCodeComputed"
    :data-q-a="dataQA"
    @input="handleInputEvent"
    @blur="handleBlurEvent"
  />
</template>

<script>
import TextInput from '@shared/components/TextInput.vue'

const ERRORS = {
  EMPTY: 1,
  INVALID: 2,
  CUSTOM: 3,
  CONTAINS_ILLEGAL_CHARACTERS: 4,
}

const EMAIL_PATTERN =
  /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
const ILLEGAL_CHARACTERS = /[^\w@.+_-]/

const getErrorCode = (value) => {
  if (!value) {
    return ERRORS.EMPTY
  }
  if (!EMAIL_PATTERN.test(value)) {
    return ERRORS.INVALID
  }
  if (ILLEGAL_CHARACTERS.test(value)) {
    return ERRORS.CONTAINS_ILLEGAL_CHARACTERS
  }

  return 0
}

export default {
  name: 'EmailField',
  components: {
    TextInput,
  },
  inheritAttrs: false,
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    dataQA: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'blur'],
  data() {
    return {
      nextValue: '',
    }
  },
  computed: {
    errorMessageComputed() {
      switch (this.errorCodeComputed) {
        case ERRORS.EMPTY:
          return this.$t('generic_field_required', {
            fieldTitle: this.$t('generic_field_email'),
          })

        case ERRORS.INVALID:
          return this.$t('generic_email_has_invalid_format')

        case ERRORS.CUSTOM:
          return this.errorMessage

        case ERRORS.CONTAINS_ILLEGAL_CHARACTERS:
          return this.$t('generic_email_address_contains_illegal_characters')

        default:
          return ''
      }
    },
    stateComputed() {
      return this.errorCodeComputed === 0
    },
    errorCodeComputed() {
      if (!this.showErrors) {
        return 0
      }

      if (this.errorMessage) {
        return ERRORS.CUSTOM
      }

      return getErrorCode(this.nextValue)
    },
  },
  created() {
    this.nextValue = this.defaultValue

    this.$emit('input', {
      name: 'email',
      value: this.nextValue,
      state: getErrorCode(this.nextValue) === 0,
    })
  },
  methods: {
    handleInputEvent(event) {
      this.nextValue = event.value.trim()
      const nextErrorCode = getErrorCode(this.nextValue)

      this.$emit('input', {
        name: event.name,
        value: this.nextValue,
        state: nextErrorCode === 0,
      })
    },
    handleBlurEvent(data) {
      this.nextValue = data.value.trim()
      const nextErrorCode = getErrorCode(this.nextValue)

      this.$emit('blur', {
        event: data.event,
        name: 'email',
        value: this.nextValue,
        state: nextErrorCode === 0,
      })
    },
  },
}
</script>
